@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNovaRegular.eot');
  src: local('ProximaNovaRegular'), url('../fonts/ProximaNovaRegular.woff') format('woff'), url('../fonts/ProximaNovaRegular.ttf') format('truetype');
}


html {
  position: relative;
  min-height: 100%;
  background-color: #111;
}

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: visible;
  font-size: 13px;
  font-weight: 500;
  /* background: linear-gradient(180deg, #24262b 0%, #24262b 100%); */
  /* background: rgb(177 119 78 / 70%); */
  font-family: 'Proxima Nova', sans-serif;
  color: #fff;
  cursor: default;
  user-select: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
}

.table .thead-light th {
  font-family: 'Proxima Nova', sans-serif;
}

h1 {
  line-height: 43px;
  font-size: 36px;
}

h2 {
  line-height: 35px;
  font-size: 30px;
}

h3 {
  line-height: 30px;
  font-size: 24px;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 13px;
}

p {
  line-height: 1.6;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 12px;
}

* {
  outline: none !important;
}

a {
  font-family: 'Proxima Nova', sans-serif;
  color: #F8F8F8;
}

a:hover, a:active, a:focus {
  outline: 0;
  text-decoration: none;
  color: #bec3d8;
}

.title-text,
.header-title {
  margin-bottom: 8px;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  font-size: 15px;
  font-weight: 500;
  margin-top: 0;
  color: #fff;
  text-shadow: 0 0 1px rgba(40, 54, 95, 0.1);
}

.page-title-box {
  padding: 25px 0;
}

.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  color: #fff;
}

.dropdown-menu.show {
  border: 0;
}

.page-title-box .breadcrumb {
  font-size: 13px;
  margin-bottom: 0;
  padding: 2px 0;
  background-color: transparent;
}

.br-theme-bars-square .br-widget a {
  background-color: #1d1f23;
}

.br-theme-fontawesome-stars-o .br-widget a:after,
.br-theme-css-stars .br-widget a:after,
.br-theme-fontawesome-stars .br-widget a:after {
  color: #50649c;
}



.slimScrollDiv {
  height: auto !important;
}

.m-b-30 {
  margin-bottom: 30px;
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.thumb-xxs {
  height: 24px !important;
  width: 24px !important;
  font-size: 10px;
  font-weight: 700;
}

.thumb-xs {
  height: 32px !important;
  width: 32px !important;
  font-size: 10px;
  font-weight: 700;
}

.thumb-sm {
  height: 36px !important;
  width: 36px !important;
  font-size: 12px;
  font-weight: 700;
}

.thumb-md {
  height: 48px;
  width: 48px;
  font-size: 14px;
  font-weight: 700;
}

.thumb-lg {
  height: 60px;
  width: 60px;
  font-size: 20px;
  font-weight: 700;
}

.thumb-xl {
  height: 78px;
  width: 78px;
  font-size: 28px;
  font-weight: 700;
}

/* =============
   Bootstrap-custom
============= */
.container-fluid {
  padding-right: 12px;
  padding-left: 12px;
}

.row {
  margin-right: -8px;
  margin-left: -8px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9,
.col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
.col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12,
.col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm,
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7,
.col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3,
.col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-right: 12px;
  padding-left: 12px;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 0;
}

.breadcrumb a {
  color: #8997bd;
}

strong {
  font-weight: 500 !important;
}

.breadcrumb-item.active {
  color: #b6c2e4;
}

.shadow {
  -webkit-box-shadow: 0px 0px 3px rgba(137, 151, 189, 0.5) !important;
  box-shadow: 0px 0px 3px rgba(137, 151, 189, 0.5) !important;
}

.breadcrumb > li + li:before {
  content: "\f105" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 600;
  padding: 0 5px;
  color: #8997bd !important;
}

code {
  color: #4ac7ec;
  font-size: 13px;
}

.lead {
  font-size: 18px;
}

.img-thumbnail {
  background-color: #1d1f23;
  border: 1px solid #28365f;
}

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid #50649c;
}

.blockquote p {
  font-size: 1.05rem;
}

.blockquote.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid #50649c;
  text-align: right;
}

/* Dropdown */
.dropdown-menu {
  padding: 4px 0;
  font-size: 13px;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  border-color: #1d2a33;
  margin: 0;
}

.dropdown-item.active, .dropdown-item:active {
  color: #f1f5fa;
  text-decoration: none;
  background-color: #f7f7f7;
}

.dropdown-item {
  padding: 6px 1.5rem;
}

.dropdown-lg {
  width: 280px;
}

@media (max-width: 767px) {
  .dropdown-lg {
    width: 260px;
  }
}

.arrow-none:after {
  border: none;
  margin: 0;
  display: none;
}

.list-group-item {
  border: 1px solid #283158;
  background-color: #1d1f23;
}

/* Navs & Tabs */
.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
  background-color: #33363c;
  color: #ffffff;
}

.nav-pills > .active > a > .badge {
  color: #33363c;
}

.small,
small {
  font-size: 11px;
}

.toast {
  border-radius: 0;
}

.toast .toast-header {
  background-color: #28365f;
  color: #fff;
  border-bottom: none;
}

.toast .toast-header .close {
  color: #fff;
}

.toast .toast-body {
  background-color: #1d1f23;
}

/* Border colors */
.border {
  border: 1px solid #283158 !important;
}

.border-primary {
  border-color: #33363c !important;
}

.border-success {
  border-color: #1ecab8 !important;
}

.border-info {
  border-color: #505d82 !important;
}

.border-warning {
  border-color: #f3c74d !important;
}

.border-danger {
  border-color: #f1646c !important;
}

.border-dark {
  border-color: #111 !important;
}

.border-pink {
  border-color: #ff5da0 !important;
}

.border-purple {
  border-color: #7551e9 !important;
}

.border-custom {
  border-color: #33363c !important;
}

/* Pagination/ Pager */
.pagination .page-link {
  color: #b6c2e4;
  background-color: #1d1f23;
  border: 1px solid #283158;
}

.pagination .page-item.active .page-link {
  background-color: #33363c;
  border-color: #33363c;
  color: #ffffff;
}

.pagination .page-link:focus, .pagination .page-link:hover {
  color: #f1f5fa;
  background-color: #262f50;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(77, 121, 246, 0.33);
  box-shadow: 0 0 0 0.2rem rgba(77, 121, 246, 0.33);
}

.pagination .form-control {
  border-color: transparent;
  font-size: 12px;
  padding: 0;
  height: calc(16px + 1px);
  background-color: transparent;
}

.pagination .form-control:focus {
  border-color: transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination .page-item.disabled .page-link {
  color: #2e3e6d;
  pointer-events: none;
  cursor: auto;
  background-color: #1e2543;
  border-color: #283158;
}

.pager li > a,
.pager li > span {
  border-radius: 3px;
  color: #f1f5fa;
}

.list-group-item.active, .list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #33363c;
  border-color: #33363c;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: fade(#ffffff, 70%);
}

.scrollspy-example {
  position: relative;
  height: 250px;
  margin-top: .5rem;
  overflow: auto;
}

.scrollspy-example-2 {
  position: relative;
  height: 350px;
  overflow: auto;
}

.navbar-brand.navbar-scroll-brand {
  margin: 8px;
  display: block;
  background-color: #2f3d6e;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  padding: 16px 0;
  border: 1px solid #28365f;
  border-radius: 3px;
}

.popover-header {
  margin-top: 0;
}

.popover-body {
  background-color: #28365f;
  color: #fff;
}

.bs-popover-auto[x-placement^=top] > .arrow::after,
.bs-popover-top > .arrow::after {
  border-top-color: #28365f;
}

.bs-popover-auto[x-placement^=right] > .arrow::after,
.bs-popover-right > .arrow::after {
  border-right-color: #28365f;
}

.bs-popover-auto[x-placement^=bottom] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  border-bottom-color: #28365f;
}

.tooltip.tooltip-custom .tooltip-inner, .tooltip.tooltip-sky .tooltip-inner {
  background: #222429;
  color: #fff;
}

.tooltip.tooltip-custom.bs-tooltip-top .arrow::before,
.tooltip.tooltip-sky.bs-tooltip-top .arrow::before {
  border-top-color: #222429;
}

.apexcharts-tooltip {
  background: #28365f !important;
  color: #fff !important;
}

.apexcharts-xaxistooltip {
  background: #28365f !important;
}

.nav-tabs {
  border-bottom: 1px solid #28365f;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #28344c;
  border-color: #28365f #28365f #1d1f23;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: transparent;
}

hr {
  border-top: 1px solid #172327;
}

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.icon-demo-content {
  text-align: center;
  color: #7a88af;
}

.icon-demo-content [class^="ti-"],
.icon-demo-content [class*=" ti-"] {
  color: #7a88af;
}

.icon-demo-content i {
  display: block;
  font-size: 24px;
  margin: 0 auto 5px;
}

.icon-demo-content.emoji-icons i {
  display: block;
  font-size: 16px;
  margin: 0 auto 8px;
}

.icon-demo-content svg {
  width: 34px;
  height: 34px;
  display: block;
  margin: 0 auto 5px;
}

.icon-demo-content svg path:nth-of-type(1) {
  fill: #7a88af;
}

.icon-demo-content svg path:nth-of-type(2) {
  fill: #33363c;
}

.icon-demo-content svg path:nth-of-type(3) {
  fill: #7a88af;
}

.icon-demo-content svg circle {
  fill: #505d82;
}

.icon-demo-content svg g path:nth-of-type(1) {
  fill: #33363c;
}

.icon-demo-content svg g path:nth-of-type(2) {
  fill: #7a88af;
}

.icon-demo-content .col-sm-6 {
  margin-bottom: 30px;
}

.icon-demo-content .col-sm-6:hover i {
  color: #33363c;
}

.icon-demo-content .col-sm-6:hover path:nth-of-type(1) {
  fill: #33363c;
}

.icon-demo-content .col-sm-6:hover path:nth-of-type(2) {
  fill: #7a88af;
}


@media (min-width: 768px) {
.page-wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
}
}

.page-content {
  width: 100%;
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 10px 0px 180px 0px;
}

.left-sidenav .main-icon-menu {
  width: auto;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  background: #292f39;
  -ms-flex-align: left;
  align-items: left;
  padding: 20px 20px;
  z-index: 500;
  position: fixed;
  top: auto;
  height: 100%;
  left: 220px;
}

.left-sidenav {
  min-width: 80px;
}

.left-sidenav .main-icon-menu {
  overflow-x: hidden;
}

.ann {
  background: linear-gradient(90deg, rgba(255, 152, 0, 0.26) 0%, rgba(45, 7, 255, 0.14901960784313725) 100%);
  padding: 2px 6px;
  padding-top: 5px !important;
  margin-top: 15px;
  border-bottom: 2px solid orange;
  border-right: 2px solid orange;
}

.left-sidenav .main-icon-menu svg {
  height: 70%;
  width: 100%;
}

.left-sidenav .main-icon-menu svg.nav-svg {
  fill: #86a3f7;
}

.left-sidenav .main-icon-menu svg .svg-primary {
  fill: #d3deff;
}

.left-sidenav .main-icon-menu .nav {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 15px;
  /*margin-top: 130px;*/
}

.left-sidenav .main-icon-menu .nav-link:hover, .left-sidenav .main-icon-menu .nav-link:focus {
  color: #29335b;
}

.left-sidenav .main-icon-menu .nav-link:active, .left-sidenav .main-icon-menu .nav-link.active {
  color: #33363c;
}

.left-sidenav .main-icon-menu .nav-link + .nav-link {
  margin-top: 30px;
}

.left-sidenav .main-icon-menu .nav-link i {
  /*font-size: 15px;*/
}

.left-sidenav .main-icon-menu .nav-link i.drip-icon {
  line-height: 1;
}

.left-sidenav .main-icon-menu .nav-link i.drip-icon ::before {
  width: auto;
}

.left-sidenav .main-icon-menu .nav-link.active:before {
  content: "\f04d";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  left: 53px;
  top: 20px;
  font-size: 40px;
  line-height: 0;
  color: #1d1f23;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (min-width: 992px) {
  .left-sidenav .main-icon-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-menu-inner {
  width: 340px;
  height: 100%;
  /* background: linear-gradient(180deg, #24262b 0%, #24262b 100%); */
  background: rgb(46,49,55);
background: linear-gradient(138deg, rgba(46,49,55,1) 40%, rgba(45,48,54,1) 100%);
  box-shadow: none;
  position: fixed;
  top: 0;
  right: 0px;
  z-index: -1;
  box-shadow: -2px 0px 3px rgb(46,49,55);
}

.main-menu-inner .menu-body {
  padding: 5px;
  height: calc(100vh - 68px) !important;
  margin-top: 45px;
}

.main-menu-inner .menu-body .nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-menu-inner .menu-body .nav-item {
  position: relative;
  display: block;
}

.main-menu-inner .menu-body .nav-item + .nav-item {
  margin-top: -1px !important;
}

.main-menu-inner .menu-body .nav-item .nav-link.active i,
.main-menu-inner .menu-body .nav-item .nav-link.active {
  color: #33363c;
}

.main-menu-inner .menu-body .nav-item ul li {
  display: block;
  margin: 10px 0;
}

.main-menu-inner .menu-body .nav-item ul li > a {
  color: #91a7bb;
  font-size: 13px;
}

.main-menu-inner .menu-body .nav-item ul li > a.active {
  color: #33363c;
}

.main-menu-inner .menu-body .nav-item ul li > a:hover, .main-menu-inner .menu-body .nav-item ul li > a:focus {
  color: #7496f8;
}

.main-menu-inner .menu-body .nav-item ul li a:before {
  content: "";
  position: absolute;
  left: 22px;
  margin-top: 5px;
  width: 8px;
  height: 8px;
  border: 1px solid #7081b9;
  border-radius: 50%;
  background: transparent;
}

.main-menu-inner .menu-body .nav-item ul li a.active:before {
  content: "";
  border: none;
  background: #33363c;
}

.main-menu-inner .menu-body .nav-item.mm-active .menu-arrow i:before {
  content: "\F140";
}

.main-menu-inner .menu-body .nav-link {
  position: relative;
  color: #91a7bb;
  font-size: 13px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 44px;
}

.main-menu-inner .menu-body .nav-link i {
  color: #91a7bb;
  font-size: 15px;
  font-weight: 400;
  line-height: 0;
  margin-right: 10px;
}

.main-menu-inner .menu-body .nav-link i.drip-icon {
  line-height: .9;
}

.main-menu-inner .menu-body .nav-link i.drip-icon::before {
  width: auto;
}

.main-menu-inner .menu-body .nav-link:hover, .main-menu-inner .menu-body .nav-link:focus,
.main-menu-inner .menu-body .nav-link:hover i,
.main-menu-inner .menu-body .nav-link:focus i {
  color: #7496f8;
}

.main-menu-inner .menu-body .nav-link:active, .main-menu-inner .menu-body .nav-link.active {
  color: #33363c;
}

.main-menu-inner .menu-body .nav-link .menu-arrow i {
  width: 15px;
  float: right;
}

@media (min-width: 992px) {
  .main-menu-inner.active {
    display: block;
  }
}

.main-icon-menu-pane {
  /* margin-bottom: 20px; */
  display: none;
}

.main-icon-menu-pane.active {
  display: block;
}

.menu-title {
  font-size: 16px;
  font-weight: 500;
  color: #acb3ce;
  line-height: 1;
  margin: 10px 0 0;
  width: 100%;
  display: inline-block;
  /*border-bottom: 2px solid #2b2e33;*/
}

.menu-sub-text {
  display: block;
  font-size: 13px;
  color: #7a88af;
  margin-bottom: 12px;
}

.enlarge-menu .main-menu-inner {
  display: none;
}

.enlarge-menu .topbar .topbar-left {
  margin-left: 0;
  width: 240px;
}

@media (min-width: 1200px) {
  .main-menu-inner {
    border-right: 0;
    z-index: -1;
  }
}

@media (max-width: 1024px) {
  .left-sidenav {
    position: fixed;
    top: 70px;
    overflow-y: auto;
    z-index: 1;
    bottom: 0;
  }
  .page-content {
    min-height: 100vh;
    padding: 10px 10px 10px 10px;
  }
  .enlarge-menu .topbar .topbar-left {
    margin-left: 0;
    width: 70px;
  }
}

@media (min-width: 992px) {
  .main-icon-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-menu-inner .menu-body .nav-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
  .main-menu-inner.active {
    display: block;
  }
  .main-menu-inner.active {
    display: block;
  }
}

@media (max-width: 991.98px) {
  .main-icon-menu-active .main-icon-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-icon-menu-active .main-menu-inner {
    display: block;
  }
}

@media (min-width: 680px) {
  .page-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  background: #282828;
}

.topbar .topbar-left {
  float: left;
  /* text-align: center; */
  /* height: 70px; */
  padding: 10px 0px;
  position: relative;
  width: 170px;
  z-index: 1;
  margin-left: 0px;
}

.topbar .topbar-left span {
  color: #FFF;
}

.topbar .topbar-left .logo {
  /* line-height: 70px; */
  padding: 10px 0px;
}

.topbar .topbar-left .logo .logo-sm {
  height: 18px;
  vertical-align: -11px;
}

.topbar .topbar-left .logo .logo-sm a {
  font-size: 25px;
  color: #EEE;
}

.topbar .topbar-left .logo .logo-lg {
  height: 14px;
  margin-left: 2px;
}

.navbar-custom {
    /* background: #24262b; */
    padding: 10px 10px 0px 0;
    margin-left: 0;
    /* min-height: 70px; */
    position: relative;
    box-shadow: 0px 0px 3px rgba(31, 30, 47, 0.05);
   -webkit-box-shadow: 0px 0px 3px rgba(31, 30, 47, 0.05);
}

.navbar-custom .nav-link {
  padding: 0 0.75rem;
  color: #828db1;
  line-height: 70px;
  max-height: 70px;
}

.navbar-custom .nav-link .nav-icon {
  font-size: 18px;
    color: #F15822;
}

.navbar-custom .dropdown-toggle:after {
  content: initial;
}

.navbar-custom .topbar-nav li {
  float: left;
}

.navbar-custom .topbar-nav li.show .nav-link {
  background-color: #21294a;
  color: #828db1;
}

.navbar-custom .crypto-balance {
  /* margin: 14px 20px 0; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.navbar-custom .crypto-balance i {
  font-size: 24px;
  margin-right: 10px;
}

.navbar-custom .crypto-balance .btc-balance {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navbar-custom .crypto-balance .btc-balance h5 {
  color: #fff;
  font-weight: 500;
}

.navbar-custom .crypto-balance .btc-balance h5 span {
  color: #7a88af;
  font-size: 14px;
  font-weight: 400;
}

.navbar-custom .crypto-balance .btc-balance span {
  font-size: 12px;
  font-weight: 400;
}

.button-menu-mobile {
  border: none;
  color: #ffffff;
  width: 60px;
  background-color: transparent;
  cursor: po'Proxima Nova', sans-serif;
}

.nav-user .nav-user-name {
  vertical-align: middle;
}

.nav-user img {
  height: 40px;
  width: 40px;
}

/* Notification */
.noti-icon-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 9px;
}

.notification-list {
  max-height: 220px;
}

.notification-list {
  margin-left: 0;
}

.notification-list .noti-title {
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -4px 0px 0px 0px;
  width: auto;
  padding: 12px 20px;
}

.notification-list .noti-title h5 {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.notification-list .notify-item {
  padding: 10px 20px;
}

.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #ffffff;
}

.notification-list .notify-item .notify-icon img {
  margin-top: 4px;
}

.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-list .notify-item .notify-details b {
  font-weight: 500;
}

.notification-list .notify-item .notify-details small {
  display: block;
  white-space: normal;
}

.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

@media (max-width: 1024px) {
  .topbar .topbar-left {
    width: 55px;
  }
  .topbar .topbar-left b {
    display: none;
  }
  .left-sidenav .main-icon-menu {
    display: none;
  }
  .topbar .topbar-left .logo-lg {
    display: none;
  }
  .topbar .navbar-custom {
    /*margin-left: 70px;*/
  }
  .topbar .app-search .form-control,
  .topbar .app-search .form-control:focus {
    width: 230px;
  }
}

@media (max-width: 767px) {
  .app-search,
  .hidden-sm {
    display: none;
  }
}

@media (max-width: 375px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}

.footer {
  bottom: 0;
  padding: 15px;
  position: absolute;
  right: 0;
  left: 0;
  color: #b6c2e4;
}

.card {
  border: none;
  margin-bottom: 24px;
  border-radius: 3px;
  /* background-color: #1b1d23; */
  background-color: rgba(40,44,49,255);
  -webkit-box-shadow: 0px 0px 3px rgba(31, 30, 47, 0.05);
  box-shadow: 0px 0px 3px rgba(31, 30, 47, 0.05);
}

.card-border {
  border: 1px solid #28365f;
}

.card-header {
  background-color: #28365f;
  border-bottom: 1px solid #28365f;
}

.card-footer {
  background-color: #262f50;
  border-top: 1px solid #28365f;
}

.card-primary {
  background-color: #33363c;
}

.card-secondary {
  background-color: #4ac7ec;
}

.card-success {
  background-color: #1ecab8;
}

.card-warning {
  background-color: #f3c74d;
}

.card-info {
  background-color: #505d82;
}

.card-danger {
  background-color: #f1646c;
}

.card-dark {
  background-color: #111;
}

.card-light {
  background-color: #262f50;
  color: #8997bd;
}

.card-pink {
  background-color: #ff5da0;
}

.card-purple {
  background-color: #7551e9;
}

.card-blue {
  background-color: #2b55cc;
}

.btn {
  border-radius: 3px;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

.btn:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #28365f;
  box-shadow: 0 0 0 0.2rem #28365f;
}

.animation-modal,
.button-list,
.button-items {
  margin-bottom: -8px;
}

.animation-modal .btn,
.button-list .btn,
.button-items .btn {
  margin-bottom: 8px;
  margin-right: 5px;
}

.btn-xl {
  padding: 12px 24px;
  font-size: 18px;
}

.btn-sm {
  padding: 4px 8px;
  font-size: 12px;
}

.btn-xs {
  padding: 2px 10px;
  font-size: 11px;
}

.btn-round {
  border-radius: 30px;
}

.btn-square {
  border-radius: 0;
}

.btn-skew {
  -webkit-transform: skew(-15deg);
  transform: skew(-15deg);
}

.btn-skew span {
  display: inline-block;
  -webkit-transform: skew(15deg);
  transform: skew(15deg);
}

.accordion .btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.btn-outline-dashed {
  outline: 1px dashed #ffffff !important;
  outline-offset: -5px;
  -moz-outline-radius: 25px;
}

.btn-primary, .btn-success, .btn-secondary, .btn-info, .btn-warning,
.btn-danger, .btn-dark, .btn-pink, .btn-purple, .btn-blue {
  color: #ffffff;
}

/*btn-primary*/
.btn-primary {
  background-color: #F15822;
  border: 1px solid #F15822;
  -webkit-box-shadow: 0 2px 6px 0 rgba(77, 121, 246, 0.5);
  box-shadow: 0 2px 6px 0 rgba(77, 121, 246, 0.5);
  color: #000;
}

.btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover,
.open > .dropdown-toggle.btn-primary, .btn-outline-primary.active, .btn-outline-primary:active,
.show > .btn-outline-primary.dropdown-toggle, .btn-outline-primary:hover, .btn-primary.active, .btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:active, .show > .btn-primary.dropdown-toggle, .btn-primary.disabled, .btn-primary:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
a.bg-primary:focus, a.bg-primary:hover,
button.bg-primary:focus, button.bg-primary:hover {
  background-color: #3567f5 !important;
  border: 1px solid #3567f5;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus,
.btn-primary.focus, .btn-primary:focus, .btn-outline-primary.focus, .btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(77, 121, 246, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(77, 121, 246, 0.3) !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #658bf7;
}

/*btn-success*/
.btn-success {
  background-color: #1ecab8;
  border: 1px solid #1ecab8;
  -webkit-box-shadow: 0 2px 6px 0 rgba(30, 202, 184, 0.5);
  box-shadow: 0 2px 6px 0 rgba(30, 202, 184, 0.5);
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active,
.btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover,
.btn-success:not(:disabled):not(.disabled):active, .btn-success.disabled, .btn-success:disabled,
.open > .dropdown-toggle.btn-success, .btn-outline-success.active, .btn-outline-success:active,
.show > .btn-outline-success.dropdown-toggle, .btn-outline-success:hover, .btn-success.active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle,
.btn-success:active, .show > .btn-success.dropdown-toggle,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: #1bb4a4;
  border: 1px solid #1bb4a4;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus,
.btn-success.focus, .btn-success:focus, .btn-outline-success.focus, .btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(30, 202, 184, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(30, 202, 184, 0.3) !important;
}

/*btn-info*/
.btn-info {
  background-color: #505d82;
  border: 1px solid #505d82;
  -webkit-box-shadow: 0 2px 6px 0 rgba(80, 93, 130, 0.5);
  box-shadow: 0 2px 6px 0 rgba(80, 93, 130, 0.5);
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus,
.btn-info:active, .btn-info:focus, .btn-info:hover, .open > .dropdown-toggle.btn-info,
.btn-outline-info.active, .btn-outline-info:active, .btn-info.disabled, .btn-info:disabled,
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle,
.show > .btn-outline-info.dropdown-toggle, .btn-outline-info:hover, .btn-info.active, .btn-info:active,
.show > .btn-info.dropdown-toggle,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  background-color: #465272;
  border: 1px solid #465272;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus,
.btn-info.focus, .btn-info:focus, .btn-outline-info.focus, .btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(80, 93, 130, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(80, 93, 130, 0.3) !important;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #56648b;
}

/*btn-warning*/
.btn-warning {
  background-color: #f3c74d;
  border: 1px solid #f3c74d;
  -webkit-box-shadow: 0 2px 6px 0 rgba(243, 199, 77, 0.5);
  box-shadow: 0 2px 6px 0 rgba(243, 199, 77, 0.5);
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active,
.btn-warning.focus, .btn-warning:active, .btn-warning:focus, .btn-warning:hover,
.open > .dropdown-toggle.btn-warning, .btn-outline-warning.active, .btn-outline-warning:active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle,
.show > .btn-outline-warning.dropdown-toggle, .btn-outline-warning:hover, .btn-warning.active,
.btn-warning.disabled, .btn-warning:disabled,
.btn-warning:active, .show > .btn-warning.dropdown-toggle {
  background-color: #f1bf35;
  border: 1px solid #f1bf35;
  color: #ffffff;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus,
.btn-warning.focus, .btn-warning:focus, .btn-outline-warning.focus, .btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(243, 199, 77, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(243, 199, 77, 0.3) !important;
}

/*btn-danger*/
.btn-danger {
  background-color: #f1646c;
  border: 1px solid #f1646c;
  -webkit-box-shadow: 0 2px 6px 0 rgba(241, 100, 108, 0.5);
  box-shadow: 0 2px 6px 0 rgba(241, 100, 108, 0.5);
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:active, .btn-danger:focus, .btn-danger:hover, .btn-danger.active,
.btn-danger.focus, .btn-danger:active, .btn-danger:focus, .btn-danger:hover,
.open > .dropdown-toggle.btn-danger, .btn-outline-danger.active, .btn-outline-danger:active,
.show > .btn-outline-danger.dropdown-toggle, .btn-outline-danger:hover, .btn-danger.active,
.btn-danger.disabled, .btn-danger:disabled,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle,
.btn-danger:active, .show > .btn-danger.dropdown-toggle {
  background-color: #ef4d56;
  border: 1px solid #ef4d56;
}

.show > .btn-danger.dropdown-toggle:focus,
.btn-danger.focus, .btn-danger:focus, .btn-outline-danger.focus, .btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(241, 100, 108, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(241, 100, 108, 0.3) !important;
}

/*btn-secondary*/
.btn-secondary {
  background-color: #4ac7ec;
  border: 1px solid #4ac7ec;
  -webkit-box-shadow: 0 2px 6px 0 rgba(74, 199, 236, 0.5);
  box-shadow: 0 2px 6px 0 rgba(74, 199, 236, 0.5);
}

.btn-secondary.active, .btn-secondary.focus, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover,
.open > .dropdown-toggle.btn-secondary, .btn-outline-secondary.active, .btn-outline-secondary:active,
.show > .btn-outline-secondary.dropdown-toggle, .btn-outline-secondary:hover,
.btn-secondary.active, .btn-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-outline-secondary.dropdown-toggle,
.btn-secondary:active, .show > .btn-secondary.dropdown-toggle, .btn-secondary.disabled, .btn-secondary:disabled,
a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #33c0ea !important;
  border: 1px solid #33c0ea;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus,
.btn-secondary.focus, .btn-secondary:focus, .btn-outline-secondary.focus, .btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(74, 199, 236, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(74, 199, 236, 0.3) !important;
}

/*btn-light*/
.btn-light {
  color: #fff;
  background-color: #262f50;
  border: 1px solid #28365f;
  -webkit-box-shadow: 0 0px 3px 0 rgba(112, 129, 185, 0.5);
  box-shadow: 0 0px 3px 0 rgba(112, 129, 185, 0.5);
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle,
.btn-light:active, .btn-light:focus, .btn-light:hover, .btn-light.active,
.btn-light.focus, .btn-light:active, .btn-light:focus, .btn-light:hover,
.open > .dropdown-toggle.btn-light, .btn-outline-light.active, .btn-outline-light:active,
.show > .btn-outline-light.dropdown-toggle, .btn-outline-light:hover, .btn-light.active,
.btn-light.disabled, .btn-light:disabled,
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle,
.btn-light:active, .show > .btn-light.dropdown-toggle {
  background-color: #242d4d;
  border: 1px solid #242d4d;
  color: #fff;
}

.show > .btn-light.dropdown-toggle:focus,
.btn-light.focus, .btn-light:focus, .btn-outline-light.focus, .btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(40, 54, 95, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(40, 54, 95, 0.3) !important;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
}

/*btn-dark*/
.btn-dark {
  background-color: #222;
  border: 1px solid #f1f5fa;
  -webkit-box-shadow: 0 2px 6px 0 rgba(241, 245, 250, 0.5);
  box-shadow: 0 2px 6px 0 rgba(241, 245, 250, 0.5);
  color: #222429;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus,
.btn-dark:active, .btn-dark:focus, .btn-dark:hover, .open > .dropdown-toggle.btn-dark,
.btn-outline-dark.active, .btn-outline-dark:active, .btn-dark.disabled, .btn-dark:disabled,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle,
.show > .btn-outline-dark.dropdown-toggle, .btn-outline-dark:hover {
  background-color: #222;
  border: 1px solid #111;
  color: #222429;
}

.show > .btn-dark.dropdown-toggle:focus,
.btn-dark.focus, .btn-dark:focus, .btn-outline-dark.focus, .btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(241, 245, 250, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(241, 245, 250, 0.3) !important;
}

/*btn-link*/
.btn-link {
  color: #8997bd;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  text-decoration: none;
  color: #fff;
}

/*btn-pink*/
.btn-pink {
  background-color: #ff5da0;
  border: 1px solid #ff5da0;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 93, 160, 0.5);
  box-shadow: 0 2px 6px 0 rgba(255, 93, 160, 0.5);
}

.btn-pink:hover, .btn-pink:focus, .btn-pink:active, .btn-pink.active, .btn-pink.focus,
.btn-pink:active, .btn-pink:focus, .btn-pink:hover, .open > .dropdown-toggle.btn-pink,
.btn-outline-pink.active, .btn-outline-pink:active, .btn-pink.disabled, .btn-pink:disabled,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.btn-outline-pink:not(:disabled):not(.disabled):active,
.show > .btn-outline-pink.dropdown-toggle,
.show > .btn-outline-pink.dropdown-toggle, .btn-outline-pink:hover {
  background-color: #ff4491;
  border: 1px solid #ff4491;
  color: #ffffff;
}

.show > .btn-pink.dropdown-toggle:focus,
.btn-pink.focus, .btn-pink:focus, .btn-outline-pink.focus, .btn-outline-pink:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 93, 160, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(255, 93, 160, 0.3) !important;
}

/*btn-purple*/
.btn-purple {
  background-color: #7551e9;
  border: 1px solid #7551e9;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 6px 0 rgba(117, 81, 233, 0.5);
  box-shadow: 0 2px 6px 0 rgba(117, 81, 233, 0.5);
}

.btn-purple:hover, .btn-purple:focus, .btn-purple:active, .btn-purple.active, .btn-purple.focus,
.btn-purple:active, .btn-purple:focus, .btn-purple:hover, .open > .dropdown-toggle.btn-purple,
.btn-outline-purple.active, .btn-outline-purple:active, .btn-purple.disabled, .btn-purple:disabled,
.btn-outline-purple:not(:disabled):not(.disabled).active,
.btn-outline-purple:not(:disabled):not(.disabled):active,
.show > .btn-outline-purple.dropdown-toggle,
.show > .btn-outline-purple.dropdown-toggle, .btn-outline-purple:hover {
  background-color: #633ae6;
  border: 1px solid #633ae6;
  color: #ffffff;
}

.show > .btn-purple.dropdown-toggle:focus,
.btn-purple.focus,
.btn-purple:focus,
.btn-outline-purple.focus,
.btn-outline-purple:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(117, 81, 233, 0.3);
  box-shadow: 0 0 0 2px rgba(117, 81, 233, 0.3);
}

/*btn-blue*/
.btn-blue {
  background-color: #2b55cc;
  border: 1px solid #2b55cc;
  -webkit-box-shadow: 0 2px 6px 0 rgba(43, 85, 204, 0.5);
  box-shadow: 0 2px 6px 0 rgba(43, 85, 204, 0.5);
}

.btn-blue.active, .btn-blue.focus, .btn-blue:active, .btn-blue:focus, .btn-blue:hover,
.open > .dropdown-toggle.btn-blue, .btn-outline-blue.active, .btn-outline-blue:active,
.show > .btn-outline-blue.dropdown-toggle, .btn-outline-blue:hover, .btn-blue.active, .btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:active, .show > .btn-blue.dropdown-toggle, .btn-blue.disabled, .btn-blue:disabled,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.btn-outline-blue:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue.dropdown-toggle,
a.bg-blue:focus, a.bg-blue:hover, button.bg-blue:focus, button.bg-blue:hover {
  background-color: #274cb7 !important;
  border: 1px solid #274cb7;
  color: #ffffff;
}

.btn-blue:not(:disabled):not(.disabled):active:focus, .show > .btn-blue.dropdown-toggle:focus,
.btn-blue.focus, .btn-blue:focus, .btn-outline-blue.focus, .btn-outline-blue:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(43, 85, 204, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(43, 85, 204, 0.3) !important;
}

/*btn-white*/
.btn-white {
  background-color: #ffffff;
  color: #fff;
  border: 1px solid #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0.25px #50649c;
  box-shadow: 0px 1px 4px 0.25px #50649c;
}

.btn-white.active, .btn-white.focus, .btn-white:active, .btn-white:focus, .btn-white:hover,
.open > .dropdown-toggle.btn-white, .btn-outline-white.active, .btn-outline-white:active,
.show > .btn-outline-white.dropdown-toggle, .btn-outline-white:hover, .btn-white.active, .btn-white:not(:disabled):not(.disabled):active,
.btn-white:active, .show > .btn-white.dropdown-toggle, .btn-white.disabled, .btn-white:disabled,
.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.show > .btn-outline-white.dropdown-toggle,
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle,
a.bg-white:focus, a.bg-white:hover,
button.bg-white:focus, button.bg-white:hover {
  background-color: #f2f2f2 !important;
  border: 1px solid #f2f2f2;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .show > .btn-white.dropdown-toggle:focus,
.btn-white.focus, .btn-white:focus, .btn-outline-white.focus, .btn-outline-white:focus {
  -webkit-box-shadow: 0px 1px 4px 0.25px #50649c !important;
  box-shadow: 0px 1px 4px 0.25px #50649c !important;
}

/* button Outline */
.btn-outline-primary {
  color: #33363c;
  border-color: #33363c;
}

.btn-outline-success {
  color: #1ecab8;
  border-color: #1ecab8;
}

.btn-outline-info {
  color: #505d82;
  border-color: #505d82;
}

.btn-outline-warning {
  color: #f3c74d;
  border-color: #f3c74d;
}

.btn-outline-danger {
  color: #f1646c;
  border-color: #f1646c;
}

.btn-outline-dark {
  color: #f1f5fa;
  background-image: none;
  background-color: transparent;
  border-color: #333;
}

.btn-outline-light {
  color: #7081b9;
  background-image: none;
  background-color: transparent;
  border-color: #242d4d;
}

.btn-outline-secondary {
  color: #4ac7ec;
  background-image: none;
  background-color: transparent;
  border-color: #4ac7ec;
}

.btn-outline-pink {
  color: #ff5da0;
  background-image: none;
  background-color: transparent;
  border-color: #ff5da0;
}

.btn-outline-purple {
  color: #7551e9;
  background-image: none;
  background-color: transparent;
  border-color: #7551e9;
}

.btn-outline-blue {
  color: #2b55cc;
  background-image: none;
  background-color: transparent;
  border-color: #2b55cc;
}

/*soft buttons*/
.btn-soft-primary {
  background-color: rgba(77, 121, 246, 0.1);
  color: #33363c;
}

.btn-soft-primary:hover {
  background-color: #33363c;
  color: #ffffff;
}

.btn-soft-primary:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(77, 121, 246, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(77, 121, 246, 0.2);
  background-color: rgba(77, 121, 246, 0.8);
  color: #ffffff;
}

.btn-soft-secondary {
  background-color: rgba(74, 199, 236, 0.15);
  color: #4ac7ec;
}

.btn-soft-secondary:hover {
  background-color: #4ac7ec;
  color: #ffffff;
}

.btn-soft-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(74, 199, 236, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(74, 199, 236, 0.2);
  background-color: rgba(74, 199, 236, 0.8);
  color: #ffffff;
}

.btn-soft-success {
  background-color: rgba(30, 202, 184, 0.1);
  color: #1ecab8;
}

.btn-soft-success:hover {
  background-color: #1ecab8;
  color: #ffffff;
}

.btn-soft-success:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(30, 202, 184, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(30, 202, 184, 0.2);
  background-color: rgba(30, 202, 184, 0.8);
  color: #ffffff;
}

.btn-soft-warning {
  background-color: rgba(243, 199, 77, 0.18);
  color: #f3c74d;
}

.btn-soft-warning:hover {
  background-color: #f3c74d;
  color: #ffffff;
}

.btn-soft-warning:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(243, 199, 77, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(243, 199, 77, 0.2);
  background-color: rgba(243, 199, 77, 0.8);
  color: #ffffff;
}

.btn-soft-danger {
  background-color: rgba(241, 100, 108, 0.1);
  color: #f1646c;
}

.btn-soft-danger:hover {
  background-color: #f1646c;
  color: #ffffff;
}

.btn-soft-danger:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(241, 100, 108, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(241, 100, 108, 0.2);
  background-color: rgba(241, 100, 108, 0.8);
  color: #ffffff;
}

.btn-soft-info {
  background-color: rgba(80, 93, 130, 0.1);
  color: #505d82;
}

.btn-soft-info:hover {
  background-color: #505d82;
  color: #ffffff;
}

.btn-soft-info:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(80, 93, 130, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(80, 93, 130, 0.2);
  background-color: rgba(80, 93, 130, 0.8);
  color: #ffffff;
}

.btn-soft-dark {
  background-color: rgba(241, 245, 250, 0.1);
  color: #f1f5fa;
}

.btn-soft-dark:hover {
  background-color: #222;
  color: #ffffff;
}

.btn-soft-dark:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(241, 245, 250, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(241, 245, 250, 0.2);
  background-color: rgba(44, 43, 45, 0.8);
  color: #ffffff;
}

.btn-soft-pink {
  background-color: rgba(255, 93, 160, 0.1);
  color: #ff5da0;
}

.btn-soft-pink:hover {
  background-color: #ff5da0;
  color: #ffffff;
}

.btn-soft-pink:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(255, 93, 160, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(255, 93, 160, 0.2);
  background-color: rgba(255, 93, 160, 0.8);
  color: #ffffff;
}

.btn-soft-purple {
  background-color: rgba(117, 81, 233, 0.1);
  color: #7551e9;
}

.btn-soft-purple:hover {
  background-color: #7551e9;
  color: #ffffff;
}

.btn-soft-purple:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(117, 81, 233, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(117, 81, 233, 0.2);
  background-color: rgba(117, 81, 233, 0.8);
  color: #ffffff;
}

.btn-soft-blue {
  background-color: rgba(43, 85, 204, 0.1);
  color: #2b55cc;
}

.btn-soft-blue:hover {
  background-color: #2b55cc;
  color: #ffffff;
}

.btn-soft-blue:focus {
  -webkit-box-shadow: 0 0 0 0.1rem rgba(43, 85, 204, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(43, 85, 204, 0.2);
  background-color: rgba(43, 85, 204, 0.8);
  color: #ffffff;
}

/* Social Buttons */
.btn-social-icon a {
  width: 36px;
  height: 36px;
  padding-left: 10px;
  -webkit-transition: all 150ms ease-in;
  transition: all 150ms ease-in;
}

.btn-social-icon a:hover {
  opacity: .7;
  transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
}

.btn-social-icon img {
  width: 17px;
  vertical-align: middle;
}

.btn-facebook {
  color: #ffffff !important;
  background-color: #3b5998;
}

.btn-twitter {
  color: #ffffff !important;
  background-color: #00aced;
}

.btn-dropbox {
  color: #ffffff !important;
  background-color: #007ee5;
}

.btn-skype {
  color: #ffffff !important;
  background-color: #00aff0;
}

.b-round {
  border-radius: 40px;
}

.alert {
  position: relative;
  border: 0;
}

.alert.icon-custom-alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.alert .alert-icon {
  font-size: 24px;
  margin-right: 8px;
}

.alert .alert-text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.alert .alert-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.alert .alert-link {
  font-weight: 600;
}

.alert.alert-primary {
  color: #ffffff;
  background-color: #33363c;
}

.alert.alert-secondary {
  color: #222429;
  background-color: #4ac7ec;
}

.alert.alert-success {
  color: #ffffff;
  background-color: #1ecab8;
}

.alert.alert-warning {
  color: #eff2f9;
  background-color: #f3c74d;
}

.alert.alert-info {
  color: #ffffff;
  background-color: #505d82;
}

.alert.alert-danger {
  color: #ffffff;
  background-color: #f1646c;
}

.alert.alert-dark {
  color: #fff;
  background-color: #222;
}

.alert.alert-light {
  color: #b6c2e4;
  background-color: #262f50;
}

.alert.alert-pink {
  color: #ffffff;
  background-color: #ff5da0;
}

.alert.alert-blue {
  color: #ffffff;
  background-color: #2b55cc;
}

.alert.alert-purple {
  color: #ffffff;
  background-color: #7551e9;
}

.alert.alert-outline-success {
  border: 1px solid #1ecab8;
  background-color: transparent;
  color: #1ecab8;
}

.alert.alert-outline-danger {
  border: 1px solid #f1646c;
  background-color: transparent;
  color: #f1646c;
}

.alert.alert-outline-primary {
  border: 1px solid #33363c;
  background-color: transparent;
  color: #33363c;
}

.alert.alert-outline-warning {
  border: 1px solid #f3c74d;
  background-color: transparent;
  color: #f3c74d;
}

.alert.alert-outline-info {
  border: 1px solid #505d82;
  background-color: transparent;
  color: #505d82;
}

.alert.alert-outline-pink {
  border: 1px solid #ff5da0;
  background-color: transparent;
  color: #ff5da0;
}

.alert.alert-outline-purple {
  border: 1px solid #7551e9;
  background-color: transparent;
  color: #7551e9;
}

.alert.alert-outline-blue {
  border: 1px solid #2b55cc;
  background-color: transparent;
  color: #2b55cc;
}

.alert.alert-outline-secondary {
  border: 1px solid #4ac7ec;
  background-color: transparent;
  color: #4ac7ec;
}

.alert.alert-outline-dark {
  border: 1px solid #111;
  background-color: transparent;
  color: #f1f5fa;
}

.alert.alert-primary-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(77, 121, 246, 0.06), 0 1px 0 0 rgba(77, 121, 246, 0.02);
  box-shadow: 0 0 24px 0 rgba(77, 121, 246, 0.06), 0 1px 0 0 rgba(77, 121, 246, 0.02);
}

.alert.alert-success-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(30, 202, 184, 0.06), 0 1px 0 0 rgba(30, 202, 184, 0.02);
  box-shadow: 0 0 24px 0 rgba(30, 202, 184, 0.06), 0 1px 0 0 rgba(30, 202, 184, 0.02);
}

.alert.alert-info-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(80, 93, 130, 0.06), 0 1px 0 0 rgba(80, 93, 130, 0.02);
  box-shadow: 0 0 24px 0 rgba(80, 93, 130, 0.06), 0 1px 0 0 rgba(80, 93, 130, 0.02);
}

.alert.alert-warning-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(243, 199, 77, 0.06), 0 1px 0 0 rgba(243, 199, 77, 0.02);
  box-shadow: 0 0 24px 0 rgba(243, 199, 77, 0.06), 0 1px 0 0 rgba(243, 199, 77, 0.02);
}

.alert.alert-danger-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(241, 100, 108, 0.06), 0 1px 0 0 rgba(241, 100, 108, 0.02);
  box-shadow: 0 0 24px 0 rgba(241, 100, 108, 0.06), 0 1px 0 0 rgba(241, 100, 108, 0.02);
}

.alert.alert-dark-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(241, 245, 250, 0.06), 0 1px 0 0 rgba(241, 245, 250, 0.02);
  box-shadow: 0 0 24px 0 rgba(241, 245, 250, 0.06), 0 1px 0 0 rgba(241, 245, 250, 0.02);
}

.alert.alert-pink-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(255, 93, 160, 0.06), 0 1px 0 0 rgba(255, 93, 160, 0.02);
  box-shadow: 0 0 24px 0 rgba(255, 93, 160, 0.06), 0 1px 0 0 rgba(255, 93, 160, 0.02);
}

.alert.alert-purple-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(117, 81, 233, 0.06), 0 1px 0 0 rgba(117, 81, 233, 0.02);
  box-shadow: 0 0 24px 0 rgba(117, 81, 233, 0.06), 0 1px 0 0 rgba(117, 81, 233, 0.02);
}

.alert.alert-blue-shadow {
  -webkit-box-shadow: 0 0 24px 0 rgba(43, 85, 204, 0.06), 0 1px 0 0 rgba(43, 85, 204, 0.02);
  box-shadow: 0 0 24px 0 rgba(43, 85, 204, 0.06), 0 1px 0 0 rgba(43, 85, 204, 0.02);
}

.bg-primary {
  background-color: #5664d2 !important;
}

.bg-secondary {
  background-color: #4ac7ec !important;
}

.bg-success {
  background-color: #1ecab8 !important;
}

.bg-warning {
  background-color: #f3c74d !important;
}

.bg-info {
  background-color: #505d82 !important;
}

.bg-danger {
  background-color: #f1646c !important;
}

.bg-dark {
  background-color: #1d1f23 !important;
}

.bg-light {
  background-color: #262f50 !important;
}

.bg-pink {
  background-color: #ff5da0 !important;
}

.bg-purple {
  background-color: #7551e9 !important;
}

.bg-blue {
  background-color: #2b55cc !important;
}

.bg-soft-primary {
  background-color: rgba(77, 121, 246, 0.15) !important;
  color: #33363c !important;
}

.bg-soft-secondary {
  background-color: rgba(74, 199, 236, 0.15) !important;
  color: #4ac7ec !important;
}

.bg-soft-success {
  background-color: rgba(30, 202, 184, 0.15) !important;
  color: #1ecab8 !important;
}

.bg-soft-warning {
  /* background-color: rgba(243, 199, 77, 0.15) !important; */
  color: #f3c74d !important;
}

.bg-soft-info {
  background-color: rgba(80, 93, 130, 0.15) !important;
  color: #505d82 !important;
}

.bg-soft-danger {
  background-color: rgba(241, 100, 108, 0.15) !important;
  color: #f1646c !important;
}

.bg-soft-pink {
  background-color: rgba(255, 93, 160, 0.15) !important;
  color: #ff5da0 !important;
}

.bg-soft-purple {
  background-color: rgba(117, 81, 233, 0.15) !important;
  color: #7551e9 !important;
}

.bg-soft-blue {
  background-color: rgba(43, 85, 204, 0.15) !important;
  color: #2b55cc !important;
}

.bg-soft-dark {
  background-color: rgba(241, 245, 250, 0.15) !important;
  color: #f1f5fa !important;
}

.badge {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.badge.badge-soft-primary {
  background-color: rgba(77, 121, 246, 0.15) !important;
  color: #33363c !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(77, 121, 246, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(77, 121, 246, 0.05);
}

.badge.badge-soft-secondary {
  background-color: rgba(74, 199, 236, 0.15) !important;
  color: #4ac7ec !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(74, 199, 236, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(74, 199, 236, 0.05);
}

.badge.badge-soft-success {
  background-color: rgba(30, 202, 184, 0.15) !important;
  color: #1ecab8 !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(30, 202, 184, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(30, 202, 184, 0.05);
}

.badge.badge-soft-warning {
  background-color: rgba(243, 199, 77, 0.15) !important;
  color: #f3c74d !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(243, 199, 77, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(243, 199, 77, 0.05);
}

.badge.badge-soft-info {
  background-color: rgba(80, 93, 130, 0.15) !important;
  color: #505d82 !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(80, 93, 130, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(80, 93, 130, 0.05);
}

.badge.badge-soft-danger {
  background-color: rgba(241, 100, 108, 0.15) !important;
  color: #f1646c !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(241, 100, 108, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(241, 100, 108, 0.05);
}

.badge.badge-soft-pink {
  background-color: rgba(255, 93, 160, 0.15) !important;
  color: #ff5da0 !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(255, 93, 160, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(255, 93, 160, 0.05);
}

.badge.badge-soft-purple {
  background-color: rgba(117, 81, 233, 0.15) !important;
  color: #7551e9 !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(117, 81, 233, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(117, 81, 233, 0.05);
}

.badge.badge-soft-dark {
  background-color: rgba(241, 245, 250, 0.15) !important;
  color: #f1f5fa !important;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(241, 245, 250, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(241, 245, 250, 0.05);
}

.text-custom {
  color: #33363c !important;
}

.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #f1646c !important;
}

.text-muted {
  color: #7a88af !important;
}

.text-primary {
  color: #33363c !important;
}

.text-secondary {
  color: #4ac7ec !important;
}

.text-warning {
  color: #f3c74d !important;
}

.text-blue {
  color: #2b55cc !important;
}

.text-success {
  color: #1ecab8 !important;
}

.text-info {
  color: #505d82 !important;
}

.text-pink {
  color: #ff5da0 !important;
}

.text-purple {
  color: #7551e9 !important;
}

.text-light {
  color: #262f50 !important;
}

.text-dark {
  color: #222 !important;
}

.badge {
  -webkit-box-shadow: 0px 0px 3px rgba(31, 30, 47, 0.05);
  box-shadow: 0px 0px 3px rgba(31, 30, 47, 0.05);
  padding: 4px 6px 3px;
  font-weight: 400;
  line-height: 10px;
}

.badge-custom {
  background: #33363c;
  color: #ffffff;
}

.badge-primary {
  background-color: #33363c;
}

.badge-secondary {
  background-color: #4ac7ec;
}

.badge-success {
  background-color: #1ecab8;
}

.badge-info {
  background-color: #505d82;
}

.badge-warning {
  background-color: #f3c74d;
  color: #ffffff;
}

.badge-danger {
  background-color: #f1646c;
}

.badge-purple {
  background-color: #7551e9;
  color: #ffffff;
}

.badge-pink {
  background-color: #ff5da0;
  color: #ffffff;
}

.badge-blue {
  background-color: #2b55cc;
  color: #ffffff;
}

.badge-dark {
  background-color: #222;
}

/*Badge Soft Color*/
.badge-soft-success {
  color: #1ecab8;
  background-color: rgba(30, 202, 184, 0.2);
}

.badge-soft-primary {
  color: #33363c;
  background-color: rgba(77, 121, 246, 0.2);
}

.badge-soft-danger {
  color: #f1646c;
  background-color: rgba(241, 100, 108, 0.2);
}

.badge-soft-info {
  color: #505d82;
  background-color: rgba(80, 93, 130, 0.2);
}

.badge-soft-warning {
  color: #f3c74d;
  background-color: rgba(243, 199, 77, 0.2);
}

.badge-soft-pink {
  color: #ff5da0;
  background-color: rgba(255, 93, 160, 0.2);
}

.badge-soft-purple {
  color: #7551e9;
  background-color: rgba(117, 81, 233, 0.2);
}

.badge-soft-blue {
  color: #2b55cc;
  background-color: rgba(43, 85, 204, 0.2);
}

.badge-soft-dark {
  color: #f1f5fa;
  background-color: rgba(241, 245, 250, 0.2);
}

.dropup .dropdown-toggle::after,
.dropright .dropdown-toggle::after,
.dropleft .dropdown-toggle::before,
.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  padding: 0 0;
  font-size: 13px;
  -webkit-box-shadow: 0 3px 12px rgba(112, 129, 185, 0.05);
  box-shadow: 0 3px 12px rgba(112, 129, 185, 0.05);
  border-color: #000000;
  background: #000000;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  padding: 5px 8px;
  color: #77939e;
  font-weight: 500;
  font-size: 13px;
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
  text-decoration: none;
  /* background-color: #282828; */
  background-color:#282828;
  color: #FFF;
  border-radius: 4px;
}

.dropdown-divider {
  border-top: 1px solid #283158;
  margin: 0;
}

.dropdown-item-text {
  color: #fff;
}

.progress {
  background-color: #28365f;
  height: 14px;
}

.progress .progress-bar {
  background-color: #33363c;
}

/* Progressbar Vertical */
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical .progress-bar {
  width: 100%;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical-bottom .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
  width: 5px !important;
}

.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}

.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
  width: 15px !important;
}

.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}

.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
  width: 20px !important;
}

.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}

.progress-icon-spin {
  font-size: 36px;
}

.table th {
  font-weight: 500;
  border-top: 1px solid #272f52;
}

.table td {
  /* border-top: 1px solid #272f52; */
}

.table thead th {
  border-bottom: 2px solid #272f52;
}

.table-bordered {
  border: 1px solid #272f52 !important;
}

.table-bordered thead {
  background-color: #262f50;
}

.table-bordered thead td,
.table-bordered thead th {
  font-weight: 500;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #272f52;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #1e2543;
}

.table {
  color: #869ab8;
}

.table thead tr th {
  font-weight: 500;
  color: #fff;
  font-size: 13px;
}

.table td {
  vertical-align: middle;
}

.table .thead-light th {
  color: #fff;
  background-color: #28365f;
  border-color: #272f52;
}

.table-hover tbody tr:hover {
  background-color: #222429;
  color: #fff;
}

.table-dark thead tr th {
  background-color: #262f50;
  border-top-color: transparent;
}

.table-dark tbody > tr > td,
.table-dark th {
  color: #28365f;
  background-color: #333;
}

.dataTables_wrapper.container-fluid {
  padding: 0;
}

/*===Datatable===*/
.table td {
  vertical-align: middle;
}

.table td.highlight {
  font-weight: bold;
  color: #505d82;
}

.table td.details-control {
  cursor: po'Proxima Nova', sans-serif;
}

.table tr.shown td.details-control {
}

/*== Table Responsive ==*/
.table-rep-plugin .btn-toolbar {
  display: block;
}

.table-rep-plugin .btn-default {
  border-color: rgba(77, 121, 246, 0.3);
  background-color: rgba(77, 121, 246, 0.2);
  color: #33363c;
  margin-right: 5px;
}

.table-rep-plugin .btn-default:focus {
  color: #33363c;
}

.table-rep-plugin .btn-default.btn-primary:active,
.table-rep-plugin .btn-default.btn-primary:hover,
.table-rep-plugin .btn-default.btn-primary:focus {
  border-color: rgba(77, 121, 246, 0.3) !important;
  background-color: rgba(77, 121, 246, 0.2) !important;
  color: #33363c;
}

.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: normal;
}

.table-rep-plugin table.focus-on tbody tr.focused th,
.table-rep-plugin table.focus-on tbody tr.focused td {
  background-color: #33363c;
  color: #ffffff;
}

.table-responsive[data-pattern="priority-columns"] {
  border: 1px solid #283158;
}

/* Sticky Header */
.fixed-solution .sticky-table-header {
  top: 70px !important;
  background-color: #28365f;
  border-color: #272f52;
}

/*====Footable table====*/
.dropdown-menu-right {
  right: 0;
  left: auto;
}

.open > .dropdown-menu {
  display: block;
}

.table.footable-details {
  color: #fff;
}

.footable-filtering-external ul.dropdown-menu > li > a.custom-control input[type=checkbox],
table.footable > thead > tr.footable-filtering > th ul.dropdown-menu > li > a.custom-control input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 5px;
}

.footable-filtering-external ul.dropdown-menu > li > a.custom-control > label,
table.footable > thead > tr.footable-filtering > th ul.dropdown-menu > li > a.custom-control > label {
  display: block;
  padding-left: 30px;
}

td.footable-sortable > span.fooicon,
th.footable-sortable > span.fooicon {
  right: auto !important;
  padding-left: 5px;
}

/*====jsgrid table====*/
.jsgrid {
  height: auto !important;
}

.jsgrid th {
  font-weight: 500;
}

.jsgrid-row > .jsgrid-cell {
  background: #1e2543;
}

.jsgrid-edit-row > .jsgrid-cell,
.jsgrid-filter-row > .jsgrid-cell,
.jsgrid-grid-body, .jsgrid-grid-header,
.jsgrid-header-row > .jsgrid-header-cell,
.jsgrid-insert-row > .jsgrid-cell {
  background: inherit;
  border-color: #272f52;
  height: auto !important;
  overflow: auto;
}

.jsgrid-cell {
  padding: 0.75rem;
  border: 1px solid #272f52;
}

.jsgrid-alt-row > .jsgrid-cell {
  background: transparent;
}

.jsgrid-cell input,
.jsgrid-cell select {
  border: 1px solid #272f52;
  background-color: #1d1f23;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 none;
  padding: 8px;
}

.jsgrid-header-row {
  font-weight: 300 !important;
  color: #fff;
  background-color: #262f50;
}

.jsgrid-selected-row > .jsgrid-cell {
  background: #28365f;
  border-color: #272f52;
}

.jsgrid-align-right {
  text-align: center;
}

.jsgrid-header-sortable {
  text-align: center;
}

.jsgrid-pager-container {
  margin-top: 16px;
}

.jsgrid .jsgrid-button {
  width: 22px;
  height: 22px;
  border: none;
  cursor: po'Proxima Nova', sans-serif;
  background-repeat: no-repeat;
  background-color: transparent;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top: 16px;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.img-group {
  display: inline-block;
}

.chats .avatar-badge {
  position: absolute;
  left: 1px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 0 2px #7081b9;
  box-shadow: 0 0 0 2px #7081b9;
  z-index: 2;
}

.chats .user-avatar + .user-avatar-group {
  margin-left: -20px;
}

.chats .user-avatar {
  position: relative;
  margin-bottom: 0;
  display: inline-block;
  width: 40px;
  /* height: 40px; */
  vertical-align: middle;
}

.chats .user-avatar:focus, .img-group .user-avatar:hover {
  z-index: 2;
}

.chats .user-avatar .online {
  background: #1ecab8;
}

.chats .user-avatar .offline {
  background: #f1646c;
}

.avatar-box {
  position: relative;
  display: inline-block;
}

.avatar-box .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

input.form-control::-webkit-input-placeholder {
  color: #4c557e;
}

input.form-control:-ms-input-placeholder {
  color: #4c557e;
}

.form-control::-webkit-input-placeholder {
  color: #4c557e;
}

.form-control:-ms-input-placeholder {
  color: #4c557e;
}

.form-control::-ms-input-placeholder {
  color: #4c557e;
}

.form-control::placeholder {
  color: #4c557e;
}

label {
  font-weight: 400;
  color: #fff;
  /* font-size: 13px; */
}

.custom-control-label {
  padding-top: 2px;
}

.form-control {
  color: #F8F8F8;
  font-size: 13px;
  border: 1px solid #283158;
  height: calc(2.3rem + 2px);
  background-color: transparent;
}

.btn-outline-tabs {
  color: rgb(53, 72, 79);
  font-size: 13px;
}

.btn-outline-tabs:hover {
  color: rgb(53, 72, 79);
}

.active.btn-outline-tabs {
  color: #FFF;
}

.form-control:focus {
  color: #a5b8ec;
  border-color: rgba(77, 121, 246, 0.5);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #2c2f35;
}

.custom-control-label::before {
  background-color: #222429;
  border: 1px solid #28365f;
}

.border-0 {
  border: 0 !important;
}

.custom-file-input,
.custom-file-label {
  height: calc(2.3rem + 1px);
  line-height: 26px;
  background-color: #1d1f23;
  border: 1px solid #283158;
}

.custom-file-label::after {
  height: calc(1.5em + 16px);
  line-height: 24px;
  background-color: #28365f;
  color: #fff;
}

.form-group {
  margin-bottom: 20px;
}

.custom-select:focus {
  border-color: rgba(77, 121, 246, 0.5);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #33363c;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #33363c;
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #33363c;
}

.has-success .form-control {
  border-color: #1ecab8;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .form-control {
  border-color: #f3c74d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .form-control {
  border-color: #f1646c;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-group-addon {
  border-radius: 2px;
  border: 1px solid #262f50;
}

.col-form-label {
  text-align: right;
}

.form-control.is-valid:focus,
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #1ecab8;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  background-color: #1d1f23;
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #f1646c;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #222429;
}

.custom-select {
  font-size: 13px;
  height: 38px;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #f1646c;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(77, 121, 246, 0.5);
  -webkit-box-shadow: none;
  box-shadow: none;
}

dt {
  font-weight: 600;
}

.custom-select,
.custom-select:focus {
  background-color: #1d1f23;
  border-color: #283158;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.mce-panel {
  border: 1px solid #222429 !important;
  background-color: #222429 !important;
}

.custom-control-label::before {
  content: "";
  background-color: #29335b;
  border: 1px solid #2c3762;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: po'Proxima Nova', sans-serif;
  -webkit-box-shadow: 1px 1px 1px #1d1f23, 0px 0px 1px #1d1f23;
  box-shadow: 1px 1px 1px #1d1f23, 0px 0px 1px #1d1f23;
  background: #29335b;
  border-radius: 1.3px;
  border: 0.2px solid #1d1f23;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #252f55;
}

.custom-range {
  margin-top: 8px;
  width: auto;
}

.custom-range::-webkit-slider-thumb {
  background-color: #FFF !important;
}

.custom-range::-moz-range-thumb {
  background-color: #33363c !important;
}

.custom-range::-ms-thumb {
  background-color: #33363c !important;
}

.dropzone {
  min-height: 230px;
  border: 2px dashed #28365f;
  background: #222429;
  border-radius: 6px;
}

.dropzone .dz-message {
  font-size: 26px;
}

.dropify-wrapper .dropify-preview {
  background: #222429;
}

.dropify-wrapper {
  border: 2px dashed #28365f;
  background: #222429;
}

.dropify-wrapper:hover {
  background-size: 30px 30px;
  background-image: linear-gradient(-45deg, #222429 25%, transparent 25%, transparent 50%, #222429 50%, #28365f 75%, transparent 75%, transparent);
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

.ribbon-1 {
  /* ribbon */
  /*==Ribbon-icon==*/
}

.ribbon-1 .ribbon-box {
  position: relative;
}

.ribbon-1 .ribbon {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 0px;
  left: -2px;
  color: #ffffff;
}

.ribbon-1 .ribbon.ribbon-mark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  /*content: '';*/
  border: 15px solid #33c16c;
  border-right: 10px solid transparent;
}

.ribbon-1 .ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-1 .ribbon-mark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #f1f5fa;
  border-left: 10px solid transparent;
}

.ribbon-1 .ribbon-icon {
  clear: none;
  padding: 0 5px;
  height: 42px;
  width: 30px;
  line-height: 40px;
  text-align: center;
  left: 0px;
  top: -2px;
}

.ribbon-1 .ribbon-mark.ribbon-icon:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #f1f5fa;
  border-bottom: 10px solid transparent;
}

.ribbon-1 .ribbon-mark.ribbon-right {
  right: -5px;
  left: auto;
}

.ribbon-1 .ribbon-mark {
  border-radius: 0;
  top: 0px;
  left: 0px;
}

.ribbon-1 .ribbon-mark.ribbon-icon.bg-primary:before {
  border-right-color: #33363c;
  border-bottom-color: transparent;
}

.ribbon-1 .ribbon-mark.ribbon-icon.bg-info:before {
  border-right-color: #505d82;
  border-bottom-color: transparent;
}

.ribbon-1 .ribbon-mark.ribbon-icon.bg-secondary:before {
  border-right-color: #4ac7ec;
  border-bottom-color: transparent;
}

.ribbon-1 .ribbon-mark.ribbon-icon.bg-danger:before {
  border-right-color: #f1646c;
  border-bottom-color: transparent;
}

.ribbon-1 .ribbon-mark.bg-secondary:before {
  border-color: #4ac7ec;
  border-right-color: transparent;
}

.ribbon-1 .ribbon-mark.bg-danger:before {
  border-color: #f1646c;
  border-right-color: transparent;
}

.ribbon-1 .ribbon-mark.bg-primary:before {
  border-color: #33363c;
  border-right-color: transparent;
}

.ribbon-1 .ribbon-mark.bg-info:before {
  border-color: #505d82;
  border-right-color: transparent;
}

.ribbon-1 .ribbon-mark.ribbon-right.bg-danger:before {
  border-right-color: #f1646c;
  border-left-color: transparent;
}

.ribbon-1 .ribbon-mark.ribbon-right.bg-info:before {
  border-right-color: #505d82;
  border-left-color: transparent;
}

.ribbon-1 .ribbon-mark.ribbon-right.bg-primary:before {
  border-right-color: #33363c;
  border-left-color: transparent;
}

.ribbon-1 .ribbon-mark.ribbon-right.bg-secondary:before {
  border-right-color: #4ac7ec;
  border-left-color: transparent;
}

.ribbon-1 p {
  color: #7a88af;
}

/*============ribbon-2=============*/
.ribbon-2 .ribbon-box {
  position: relative;
}

.ribbon-2 .ribbon-box .ribbon {
  position: relative;
  float: left;
  clear: both;
  padding: 5px 12px 5px 12px;
  margin: -26px 0px 15px -26px;
  color: #ffffff;
}

.ribbon-2 .ribbon-box .ribbon-primary {
  background: #33363c;
}

.ribbon-2 .ribbon-box .ribbon-primary:before {
  position: absolute;
  top: 29px;
  left: 0;
  content: "";
  border: 3px solid transparent;
  border-top-color: #1c54f4;
  border-right-color: #1c54f4;
}

.ribbon-2 .ribbon-box .ribbon-info {
  background-color: #505d82;
}

.ribbon-2 .ribbon-box .ribbon-info:before {
  position: absolute;
  top: 29px;
  left: 0;
  content: "";
  border: 3px solid transparent;
  border-top-color: #3d4662;
  border-right-color: #3d4662;
}

.ribbon-2 .ribbon-box .ribbon-danger {
  background-color: #f1646c;
}

.ribbon-2 .ribbon-box .ribbon-danger:before {
  position: absolute;
  top: 29px;
  left: 0;
  content: "";
  border: 3px solid transparent;
  border-top-color: #ed3540;
  border-right-color: #ed3540;
}

.ribbon-2 .ribbon-box .ribbon-secondary {
  background-color: #4ac7ec;
  color: #222429;
}

.ribbon-2 .ribbon-box .ribbon-secondary:before {
  position: absolute;
  top: 29px;
  left: 0;
  content: "";
  border: 3px solid transparent;
  border-top-color: #1cb9e7;
  border-right-color: #1cb9e7;
}

.ribbon-2 .ribbon-box .ribbon + p {
  clear: both;
}

.ribbon-2 .card-box {
  padding: 20px;
  border: 5px double #222429;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #1d1f23;
}

.ribbon-2 p {
  color: #7a88af;
}

.ribbon.bg-secondary {
  background-color: #4ac7ec;
  color: #f3f6f7;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  border-color: #ffffff;
}

.radio .custom-control-label::after {
  position: absolute;
  top: 7px;
}

.checkbox label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
}

.checkbox label::before {
  background-color: #1d1f23;
  border-radius: 3px;
  border: 2px solid #7a88af;
  content: "";
  display: inline-block;
  height: 18px;
  left: 0;
  top: 0px;
  margin-left: -18px;
  position: absolute;
  width: 18px;
  outline: none !important;
}

.checkbox label::after {
  color: #262f50;
  display: inline-block;
  font-size: 11px;
  height: 18px;
  left: 0;
  margin-left: -18px;
  padding-left: 3px;
  padding-top: 2px;
  position: absolute;
  width: 18px;
}

.checkbox input[type="checkbox"] {
  cursor: po'Proxima Nova', sans-serif;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 7px;
  display: table;
  width: 5px;
  height: 10px;
  border: 1px solid #f1f5fa;
  border-top-width: 0;
  border-left-width: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #262f50;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single input {
  height: 18px;
  width: 18px;
  position: absolute;
}

.checkbox.checkbox-single label {
  height: 18px;
  width: 18px;
}

.checkbox.checkbox-single label:before {
  margin-left: 0;
}

.checkbox.checkbox-single label:after {
  margin-left: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #33363c;
  border-color: #33363c;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #f1646c;
  border-color: #f1646c;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #505d82;
  border-color: #505d82;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f3c74d;
  border-color: #f3c74d;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #1ecab8;
  border-color: #1ecab8;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-purple input[type="checkbox"]:checked + label::before {
  background-color: #7551e9;
  border-color: #7551e9;
}

.checkbox-purple input[type="checkbox"]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-pink input[type="checkbox"]:checked + label::before {
  background-color: #ff5da0;
  border-color: #ff5da0;
}

.checkbox-pink input[type="checkbox"]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-dark input[type="checkbox"]:checked + label::before {
  background-color: #111;
  border-color: #222;
}

.checkbox-dark input[type="checkbox"]:checked + label::after {
  border-color: #222429;
}

/* Radios */
.radio label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
}

.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #1d1f23;
  border-radius: 50%;
  border: 2px solid #7a88af;
  content: "";
  display: inline-block;
  height: 18px;
  left: 0;
  top: 0px;
  margin-left: -18px;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 18px;
  outline: none !important;
}

.radio label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #f1f5fa;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 10px;
  left: 6px;
  margin-left: -20px;
  position: absolute;
  top: 4px;
  transform: scale(0, 0);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 10px;
}

.radio input[type="radio"] {
  cursor: po'Proxima Nova', sans-serif;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label {
  height: 17px;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #33363c;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #33363c;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #33363c;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #f1646c;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f1646c;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #f1646c;
}

.radio-info input[type="radio"] + label::after {
  background-color: #505d82;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #505d82;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #505d82;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #f3c74d;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f3c74d;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f3c74d;
}

.radio-success input[type="radio"] + label::after {
  background-color: #1ecab8;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #1ecab8;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #1ecab8;
}

.radio-purple input[type="radio"] + label::after {
  background-color: #7551e9;
}

.radio-purple input[type="radio"]:checked + label::before {
  border-color: #7551e9;
}

.radio-purple input[type="radio"]:checked + label::after {
  background-color: #7551e9;
}

.radio-pink input[type="radio"] + label::after {
  background-color: #ff5da0;
}

.radio-pink input[type="radio"]:checked + label::before {
  border-color: #ff5da0;
}

.radio-pink input[type="radio"]:checked + label::after {
  background-color: #ff5da0;
}

.radio2 label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
  margin-bottom: 10px;
}

.radio2 label::before {
  background-color: #1d1f23;
  border-radius: 50%;
  border: 2px solid transparent;
  content: "";
  display: inline-block;
  height: 16px;
  top: -1px;
  left: -2px;
  margin-left: -18px;
  position: absolute;
  width: 16px;
  outline: none !important;
}

.radio2 label::after {
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 8px;
  left: 4px;
  margin-left: -20px;
  position: absolute;
  top: 3px;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  width: 8px;
}

.radio2 input[type="radio"] {
  cursor: po'Proxima Nova', sans-serif;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.radio2 input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio2 input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted;
  border-color: #33363c;
}

.radio2 input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio2 input[type="radio"]:checked + label::before {
  border-color: #33363c;
}

.radio2 input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio2.radio-primary2 input[type="radio"] + label::before {
  background-color: #33363c;
}

.radio2.radio-primary2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-primary2 input[type="radio"]:checked + label::before {
  border-color: #33363c;
}

.radio2.radio-primary2 input[type="radio"]:checked + label::after {
  border-color: #33363c;
}

.radio2.radio-secondary2 input[type="radio"] + label::before {
  background-color: #4ac7ec;
}

.radio2.radio-secondary2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-secondary2 input[type="radio"]:checked + label::before {
  border-color: #4ac7ec;
}

.radio2.radio-secondary2 input[type="radio"]:checked + label::after {
  border-color: #4ac7ec;
}

.radio2.radio-success2 input[type="radio"] + label::before {
  background-color: #1ecab8;
}

.radio2.radio-success2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-success2 input[type="radio"]:checked + label::before {
  border-color: #1ecab8;
}

.radio2.radio-success2 input[type="radio"]:checked + label::after {
  border-color: #1ecab8;
}

.radio2.radio-danger2 input[type="radio"] + label::before {
  background-color: #f1646c;
}

.radio2.radio-danger2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-danger2 input[type="radio"]:checked + label::before {
  border-color: #f1646c;
}

.radio2.radio-danger2 input[type="radio"]:checked + label::after {
  border-color: #f1646c;
}

.radio2.radio-warning2 input[type="radio"] + label::before {
  background-color: #f3c74d;
}

.radio2.radio-warning2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-warning2 input[type="radio"]:checked + label::before {
  border-color: #f3c74d;
}

.radio2.radio-warning2 input[type="radio"]:checked + label::after {
  border-color: #f3c74d;
}

.radio2.radio-info2 input[type="radio"] + label::before {
  background-color: #505d82;
}

.radio2.radio-info2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-info2 input[type="radio"]:checked + label::before {
  border-color: #505d82;
}

.radio2.radio-info2 input[type="radio"]:checked + label::after {
  border-color: #505d82;
}

.radio2.radio-dark2 input[type="radio"] + label::before {
  background-color: #222;
}

.radio2.radio-dark2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-dark2 input[type="radio"]:checked + label::before {
  border-color: #333;
}

.radio2.radio-dark2 input[type="radio"]:checked + label::after {
  border-color: #222;
}

.radio2.radio-purple2 input[type="radio"] + label::before {
  background-color: #7551e9;
}

.radio2.radio-purple2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-purple2 input[type="radio"]:checked + label::before {
  border-color: #7551e9;
}

.radio2.radio-purple2 input[type="radio"]:checked + label::after {
  border-color: #7551e9;
}

.radio2.radio-pink2 input[type="radio"] + label::before {
  background-color: #ff5da0;
}

.radio2.radio-pink2 input[type="radio"] + label::after {
  background-color: #1d1f23;
}

.radio2.radio-pink2 input[type="radio"]:checked + label::before {
  border-color: #ff5da0;
}

.radio2.radio-pink2 input[type="radio"]:checked + label::after {
  border-color: #ff5da0;
}

/* ==============
  Range slider
===================*/
.irs-from, .irs-to, .irs-single {
  background: #33363c;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  border-top-color: #33363c;
}

.irs--round .irs-bar,
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child,
.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar {
  background-color: #33363c;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: #33363c;
}

.irs--sharp .irs-bar,
.irs--sharp .irs-handle {
  background-color: #1ecab8;
}

.irs--sharp .irs-handle > i:first-child {
  border-top-color: #1ecab8;
}

.irs--sharp .irs-line {
  background-color: #50649c;
}

.irs--square .irs-handle {
  border-color: #2b55cc;
}

.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-single,
.irs--square .irs-bar {
  background-color: #2b55cc;
}

.irs--flat .irs-min, .irs--flat .irs-max,
.irs--round .irs-min, .irs--round .irs-max,
.irs--square .irs-min, .irs--square .irs-max,
.irs--modern .irs-min, .irs--modern .irs-max {
  top: 0;
  font-size: 11px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: #fff;
  background-color: #28365f;
  border-radius: 5px;
}

.irs--modern .irs-from,
.irs--modern .irs-to,
.irs--modern .irs-single,
.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  font-size: 11px;
  line-height: 1.333;
  text-shadow: none;
  padding: 2px 5px;
  background-color: #33363c;
  color: #ffffff;
  border-radius: 5px;
}

.irs--modern .irs-from:before,
.irs--modern
.irs-to:before,
.irs--modern .irs-single:before,
.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  content: "";
  border: 3px solid transparent;
  border-top-color: #33363c;
}

.irs--modern .irs-bar {
  background: #33363c;
  background: linear-gradient(135deg, rgba(77, 121, 246, 0.5) 0%, #33363c 36%, #3567f5 61%, rgba(77, 121, 246, 0.5) 100%);
}

.irs--round .irs-handle {
  border: 4px solid #33363c;
  background-color: transparent;
  -webkit-box-shadow: 0 1px 3px rgba(77, 121, 246, 0.3);
  box-shadow: 0 1px 3px rgba(77, 121, 246, 0.3);
}

.irs--modern .irs-line {
  background-color: #28365f;
  background: -webkit-gradient(linear, left top, left bottom, from(#222429), to(#28365f));
  background: linear-gradient(to bottom, #222429 0%, #28365f 100%);
  border: 1px solid #28365f;
}

.irs--square .irs-handle,
.irs--square .irs-line,
.irs--round .irs-line,
.irs--flat .irs-line {
  background-color: #28365f;
}

.irs--sharp .irs-grid-pol,
.irs--square .irs-grid-pol,
.irs--flat .irs-grid-pol,
.irs--modern .irs-grid-pol {
  background-color: #28365f;
}

.irs--square .irs-handle.state_hover,
.irs--square .irs-handle:hover {
  background-color: #33363c;
}

.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  left: 2px;
  border: 1px solid #50649c;
  background: #28365f;
}

.irs--modern .irs-handle > i:nth-child(2) {
  background: #28365f;
  background: -webkit-gradient(linear, left top, left bottom, from(#28365f), to(#28365f));
  background: linear-gradient(to bottom, #28365f 0%, #28365f 100%);
}

.irs--modern .irs-handle > i:nth-child(3) {
  border-left: 1px solid #50649c;
  border-right: 1px solid #50649c;
}

.irs--modern .irs-handle {
  border: 1px solid #50649c;
}

.irs--flat .irs-grid-text,
.irs--square .irs-grid-text,
.irs--sharp .irs-grid-text,
.irs--modern .irs-grid-text {
  color: #fff;
  font-size: 12px;
}

.swal2-popup {
  background: #1d1f23;
}

.swal2-popup .swal2-title {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.swal2-popup #swal2-content {
  color: #7a88af;
}

.swal2-popup .swal2-file,
.swal2-popup .swal2-input,
.swal2-popup .swal2-textarea {
  border: 1px solid #283158;
  background-color: transparent;
  font-size: 1.125em;
  color: #fff;
}

.swal2-popup .swal2-file:focus,
.swal2-popup .swal2-input:focus,
.swal2-popup .swal2-textarea:focus {
  border: 1px solid #33363c;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.swal2-popup .swal2-validation-message {
  background: #1f2746;
  color: #fff;
}

.swal2-popup .swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #33363c;
  color: #ffffff;
  font-size: 1.0625em;
}

.swal2-popup .swal2-styled:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #33363c, 0 0 0 4px rgba(77, 121, 246, 0.4);
  box-shadow: 0 0 0 2px #33363c, 0 0 0 4px rgba(77, 121, 246, 0.4);
}

.swal2-popup .swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #f1646c;
  color: #ffffff;
  font-size: 1.0625em;
}

.swal2-popup .swal2-styled.swal2-cancel:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #f1646c, 0 0 0 4px rgba(241, 100, 108, 0.5);
  box-shadow: 0 0 0 2px #f1646c, 0 0 0 4px rgba(241, 100, 108, 0.5);
}

.swal2-popup .swal2-styled.swal2-cancel {
  border: 1px solid transparent;
}

.swal2-actions .btn-success:not(:disabled):not(.disabled):active,
.swal2-actions .btn-success,
.swal2-actions .btn-success:active {
  background-color: #33363c;
  border: 1px solid #33363c;
}

.swal2-actions .btn-success:focus {
  -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(77, 121, 246, 0.4);
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(77, 121, 246, 0.4);
}

.swal2-actions .btn-danger:not(:disabled):not(.disabled):active {
  color: #ffffff;
  background-color: #f1646c;
  border-color: #f1646c;
}

.swal2-actions .btn-danger:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(241, 100, 108, 0.5);
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(241, 100, 108, 0.5);
}

.swal2-actions .btn-danger {
  background-color: #f1646c;
  border: 1px solid #f1646c;
}

.swal2-actions .btn-danger:active {
  background-color: #f1646c;
  border: 1px solid #f1646c;
}

.swal2-actions .btn-danger:hover {
  background-color: #f1646c;
  border: 1px solid #f1646c;
}

.swal2-actions .btn-danger:focus {
  background-color: #f1646c;
  border: 1px solid #f1646c;
  -webkit-box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(241, 100, 108, 0.5);
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(241, 100, 108, 0.5);
}

.swal2-icon.swal2-question {
  border-color: #ff5da0;
  color: #ff5da0;
}

.custom-dd {
  max-width: 100%;
}

.dd3-content,
.dd-handle {
  height: 40px;
  padding: 8px 10px;
  border: 1px solid #28365f;
  background-color: #26345b;
  border-radius: 3px;
  font-weight: normal;
  color: #fff;
}

.dd3-content:hover,
.dd-handle:hover {
  background-color: #2b3a66;
  color: #33363c;
}

.dd-item > button {
  height: 25px;
  margin: 8px 0 0;
  font-size: 18px;
  color: #fff;
}

.dd3-item {
  margin: 5px 0;
}

.dd3-item .dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: po'Proxima Nova', sans-serif;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #33363c;
  background: #33363c;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dd3-item .dd3-handle:hover {
  background: #658bf7;
}

.dd3-item .dd3-handle:before {
  content: "\f0c9";
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 600;
  display: block;
  position: absolute;
  left: 0;
  top: 9px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #ffffff;
  font-size: 14px;
}

.dd3-content-p {
  padding: 8px 10px 8px 40px;
}

.modal-demo {
  background-color: #ffffff;
  width: 600px;
  border-radius: 4px;
  display: none;
  position: relative;
}

.modal-demo .close {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #28365f;
}

.modal-content .modal-header, .modal-content .modal-footer {
  border-color: #171e23;
  background-color: #171e23;
}

.modal-content .modal-header .modal-title, .modal-content .modal-footer .modal-title {
  color: #fff;
}

.modal-content .modal-header .close, .modal-content .modal-footer .close {
  color: #fff;
}

.modal-content .modal-body p, .modal-content h4 {
  color: #3f5963;
}

.modal-content .modal-title.custom-title {
  color: white;
}

#custom-modal .close {
  color: #fff;
}

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: #28365f;
  color: #fff;
  text-align: left;
  margin: 0px;
}

.custom-modal-text {
  padding: 20px;
  color: #8997bd;
}

.custombox-modal-flash .close,
.custombox-modal-rotatedown .close {
  top: 20px;
  z-index: 9999;
}

#session-timeout-dialog .modal-header {
  display: inline-block;
}

/* ==============
  Form-Advanced
===================*/
/* Datepicker */
.datepicker {
  border: 1px solid #28365f;
  padding: 8px;
}

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover, .datepicker table tr td.today, .datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover, .datepicker table tr td.selected,
.datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover {
  background-color: #33363c !important;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
}

.daterangepicker {
  background-color: #222b4d;
  border-color: #29335b;
}

.daterangepicker:after {
  border-bottom-color: #3b4a86;
}

.daterangepicker .calendar-table {
  border: 1px solid #2c3762;
  border-radius: 4px;
  background-color: #29335b;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border: solid #b6c2e4;
  border-width: 0 2px 2px 0;
}

.daterangepicker .drp-buttons {
  border-color: #2c3762;
}

.daterangepicker .drp-buttons .btn {
  font-weight: normal;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect,
.daterangepicker select.yearselect,
.daterangepicker select.monthselect {
  background-color: #3b4a86;
  color: #8997bd;
  border-color: #50649c;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  color: #28365f;
  background-color: #1f2746;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: rgba(77, 121, 246, 0.1);
  border-color: transparent;
  color: #33363c;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #3b4a86;
  border-color: transparent;
  color: #fff;
}

.table-condensed > thead > tr > th, .table-condensed > tbody > tr > td {
  padding: 5px;
}

/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
  padding: 9px 12px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  top: 4px;
  left: 8px;
}

/* Prism */
:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #262f50;
}

/* Rating */
.badge:empty {
  padding: 0;
}

.select2 .select2-search input::-webkit-input-placeholder,
.select2 .select2-search textarea::-webkit-input-placeholder {
  color: #4c557e !important;
}

.select2 .select2-search input:-ms-input-placeholder,
.select2 .select2-search textarea:-ms-input-placeholder {
  color: #4c557e !important;
}

.select2 .select2-search input::-ms-input-placeholder,
.select2 .select2-search textarea::-ms-input-placeholder {
  color: #4c557e !important;
}

.select2 .select2-search input::placeholder,
.select2 .select2-search textarea::placeholder {
  color: #4c557e !important;
}

.select2-dropdown {
  border: 1px solid #283158;
  background-color: #1d1f23;
  color: #7081b9;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #242d51;
}

.select2-container--default .select2-search--inline .select2-search__field {
  color: #7081b9;
  margin-top: 7px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #242d51;
  background-color: #242d51;
  color: #8997bd;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #8997bd;
  line-height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
  right: 8px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #7081b9 transparent transparent transparent;
}

.select2-container--default .select2-selection--single:focus {
  outline: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #8997bd;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 7px;
  background-color: #2f3b69;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #7081b9 transparent;
}

.select2-container .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  background-color: #1d1f23;
  border: 1px solid #242d51;
  color: #7081b9;
  height: 38px;
  outline: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border: 1px solid #50649c;
  color: #8997bd;
}

/*---datetimepicker---*/
.dtp-btn-cancel {
  margin-right: 5px;
}

.addon-color-picker .btn {
  padding: 8px;
  line-height: 0;
  border-color: #bfbfbf;
}

/*--colorpicker--*/
.asColorPicker-clear {
  display: none;
  position: absolute;
  top: 8px;
  right: 45px;
  text-decoration: none;
}

.asColorPicker-trigger {
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  width: 38px;
  border: 0;
}

.asColorPicker-dropdown {
  max-width: 260px;
}

.asColorPicker-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-right: 35px;
}

.input-title {
  font-weight: 400;
  font-size: 15px;
  color: #f1f5fa;
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
  background: #33363c;
}

.dtp table.dtp-picker-days tr > td > a.selected {
  background: rgba(77, 121, 246, 0.1);
  color: #33363c;
}

.dtp div.dtp-date, .dtp div.dtp-time {
  background: #5c84f7;
  color: #33363c;
}

.dtp div.dtp-actual-year,
.dtp div.dtp-actual-month {
  color: #ffffff;
  font-size: 14px;
  padding: 8px 0;
}

.dtp > .dtp-content {
  max-height: 555px;
}

.dtp table.dtp-picker-days tr > th {
  color: #96b0fa;
}

.dtp-actual-maxtime,
.dtp-actual-num {
  color: #ffffff;
}

.dtp table.dtp-picker-days tr > td > a,
.dtp .dtp-picker-time > a {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.dtp div.dtp-actual-num {
  font-size: 64px;
}

[class^="ti-"], [class*=" ti-"] {
  color: #ffffff;
}

.dtp .p10 > a {
  color: #33363c;
  text-decoration: none;
}

.custom-switch.switch-primary .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #33363c;
  background-color: #33363c;
}

.custom-switch.switch-primary .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(77, 121, 246, 0.25);
  box-shadow: 0 0 0 0.11px rgba(77, 121, 246, 0.25);
}

.custom-switch.switch-primary .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-secondary .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #4ac7ec;
  background-color: #4ac7ec;
}

.custom-switch.switch-secondary .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(74, 199, 236, 0.25);
  box-shadow: 0 0 0 0.11px rgba(74, 199, 236, 0.25);
}

.custom-switch.switch-secondary .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-success .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #1ecab8;
  background-color: #1ecab8;
}

.custom-switch.switch-success .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(30, 202, 184, 0.25);
  box-shadow: 0 0 0 0.11px rgba(30, 202, 184, 0.25);
}

.custom-switch.switch-success .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-warning .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #f3c74d;
  background-color: #f3c74d;
}

.custom-switch.switch-warning .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(243, 199, 77, 0.25);
  box-shadow: 0 0 0 0.11px rgba(243, 199, 77, 0.25);
}

.custom-switch.switch-warning .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-info .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #505d82;
  background-color: #505d82;
}

.custom-switch.switch-info .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(80, 93, 130, 0.25);
  box-shadow: 0 0 0 0.11px rgba(80, 93, 130, 0.25);
}

.custom-switch.switch-info .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-danger .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #f1646c;
  background-color: #f1646c;
}

.custom-switch.switch-danger .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(241, 100, 108, 0.25);
  box-shadow: 0 0 0 0.11px rgba(241, 100, 108, 0.25);
}

.custom-switch.switch-danger .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-dark .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #f1f5fa;
  background-color: #111;
}

.custom-switch.switch-dark .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(241, 245, 250, 0.25);
  box-shadow: 0 0 0 0.11px rgba(241, 245, 250, 0.25);
}

.custom-switch.switch-dark .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-dark .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #1d1f23;
}

.custom-switch.switch-purple .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #7551e9;
  background-color: #7551e9;
}

.custom-switch.switch-purple .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(117, 81, 233, 0.25);
  box-shadow: 0 0 0 0.11px rgba(117, 81, 233, 0.25);
}

.custom-switch.switch-purple .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-pink .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #ff5da0;
  background-color: #ff5da0;
}

.custom-switch.switch-pink .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(255, 93, 160, 0.25);
  box-shadow: 0 0 0 0.11px rgba(255, 93, 160, 0.25);
}

.custom-switch.switch-pink .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.custom-switch.switch-blue .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #2b55cc;
  background-color: #2b55cc;
}

.custom-switch.switch-blue .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.11px rgba(43, 85, 204, 0.25);
  box-shadow: 0 0 0 0.11px rgba(43, 85, 204, 0.25);
}

.custom-switch.switch-blue .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(137, 151, 189, 0.7);
}

.error {
  color: #f1646c;
}

.parsley-error {
  border-color: #f1646c;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}

.parsley-errors-list.filled {
  display: block;
}

.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #f1646c;
  margin-top: 5px;
}

.form-wizard-wrapper label {
  font-size: 13px;
  text-align: right;
}

.wizard > .content > .body input {
  border: 1px solid #283158;
}

.wizard > .content > .body input:focus {
  border-color: rgba(77, 121, 246, 0.5);
}

.wizard.vertical > .steps {
  width: auto;
}

.wizard > .steps > ul > li {
  width: auto;
  margin-bottom: 10px;
}

.wizard > .steps a,
.wizard > .steps a:active,
.wizard > .steps a:hover {
  margin: 3px;
  padding: 0;
  border-radius: 50px;
}

.wizard > .steps .current a,
.wizard > .steps .current a:active,
.wizard > .steps .current a:hover {
  background-color: #33363c;
  color: #ffffff;
  padding: 0 20px 0 0;
  -webkit-box-shadow: 0px 0px 0px 2.25px #33363c;
  box-shadow: 0px 0px 0px 2.25px #33363c;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:active,
.wizard > .steps .disabled a:hover,
.wizard > .steps .done a,
.wizard > .steps .done a:active,
.wizard > .steps .done a:hover {
  background-color: #222429;
  color: #33363c;
  padding: 0 20px 0 0;
  -webkit-box-shadow: 0px 0px 0px 2.25px #28365f;
  box-shadow: 0px 0px 0px 2.25px #28365f;
}

.wizard > .steps .current a .number,
.wizard > .steps .current a:active .number,
.wizard > .steps .current a:hover .number {
  border: 2px solid #ffffff;
}

.wizard > .steps .disabled a .number,
.wizard > .steps .disabled a:active .number,
.wizard > .steps .disabled a:hover .number,
.wizard > .steps .done a .number,
.wizard > .steps .done a:active .number,
.wizard > .steps .done a:hover .number {
  border-color: #33363c;
}

.wizard > .content {
  background-color: transparent;
  margin: 0 5px;
  border-radius: 0;
  min-height: 150px;
}

#form-horizontal fieldset {
  margin-top: 20px;
}

.wizard > .content > .body {
  width: 100%;
  height: 100%;
  padding: 15px 0 0;
  position: static;
}

.wizard > .steps .number {
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  width: 38px;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;
  background-color: rgba(77, 121, 246, 0.15);
}

.wizard > .actions,
.wizard.vertical > .actions {
  margin-top: 20px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:active,
.wizard > .actions .disabled a:hover {
  opacity: .65;
  background-color: #33363c;
  color: #ffffff;
  cursor: not-allowed;
}

.wizard > .actions a,
.wizard > .actions a:active,
.wizard > .actions a:hover {
  background-color: #33363c;
  border-radius: 4px;
  padding: 8px 15px;
}

@media (max-width: 768px) {
  .wizard > .steps > ul > li {
    width: 50%;
  }
  .form-wizard-wrapper label {
    text-align: left;
  }
}

@media (max-width: 520px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #25262b;
}

.mce-tinymce {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mce-flow-layout {
  background-color: #50649c !important;
}

.mce-btn {
  background: transparent !important;
}

.mce-top-part::before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mce-menubar .mce-menubtn button span {
  color: #8997bd !important;
}

.mce-caret,
.mce-menubar .mce-caret {
  border-top-color: #28365f !important;
}

.mce-menubar .mce-menubtn:hover,
.mce-menubar .mce-menubtn.mce-active,
.mce-menubar .mce-menubtn:focus {
  border-color: #28365f !important;
  background: #2c3762 !important;
  color: #8997bd !important;
  -webkit-filter: none;
  filter: none;
}

.mce-menu-item .mce-ico,
.mce-menu-item .mce-text {
  color: #8997bd !important;
}

.mce-menu-item:hover .mce-text,
.mce-menu-item.mce-selected .mce-text,
.mce-menu-item:focus .mce-text {
  color: #ffffff !important;
}

.mce-btn {
  background-color: #2c3762 !important;
}

.mce-btn:hover {
  border-color: #28365f !important;
  color: #b6c2e4 !important;
}

.mce-ico {
  color: #8997bd !important;
}

.mce-menubtn button {
  color: #8997bd !important;
}

.mce-menubar {
  border: 1px solid #28365f !important;
}

.mce-btn-group:not(:first-child) {
  border-left: 1px solid #28365f !important;
}

.mce-flow-layout {
  background-color: #1f2746 !important;
}

.mce-panel {
  border-color: #28365f !important;
  background-color: #151933 !important;
}

.mce-label {
  text-shadow: none !important;
}

.mce-menu-item:hover, .mce-menu-item:focus {
  background: #3b4a86 !important;
}

.mce-menu-item.mce-disabled,
.mce-menu-item.mce-disabled:hover {
  background: #3b4a86 !important;
}

.chart-demo {
  height: 370px;
  margin: 20px auto;
}

/* Sparkline chart */
.jqstooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #ffffff !important;
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #ffffff !important;
  -webkit-box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
}

.jqsfield {
  font-size: 12px !important;
  line-height: 18px !important;
  color: #f1f5fa !important;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
}

.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}

/* Morris chart */
.morris-chart text {
  font-family: 'Proxima Nova', sans-serif;
}

.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
  background: #222429;
  border: none;
  font-family: 'Proxima Nova', sans-serif;
  -webkit-box-shadow: 0 5px 25px 5px rgba(28, 35, 63, 0.14);
  box-shadow: 0 5px 25px 5px rgba(28, 35, 63, 0.14);
}

.morris-hover.morris-default-style .morris-hover-point {
  line-height: 28px;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  background-color: #33363c;
  color: #ffffff;
  padding: 4px 20px;
  border-radius: 5px 5px 0 0;
  margin: -10px -12px 10px;
  font-weight: 400;
}

/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: #262f50;
  z-index: 100;
  color: #f1f5fa;
  -webkit-box-shadow: 0 5px 25px 5px rgba(28, 35, 63, 0.14);
  box-shadow: 0 5px 25px 5px rgba(28, 35, 63, 0.14);
  border-radius: 1px;
}

/* Chartist chart */
.ct-golden-section:before {
  float: none;
}

.ct-chart {
  height: 300px;
}

.ct-grid {
  stroke: rgba(241, 245, 250, 0.09);
  stroke-width: 2px;
  stroke-dasharray: 3px;
}

.ct-chart .ct-label {
  fill: #7a88af;
  color: #7a88af;
  font-size: 14px;
  line-height: 1;
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 16px;
}

.ct-chart .ct-series.ct-series-a .ct-slice-donut,
.ct-chart .ct-series.ct-series-b .ct-bar {
  stroke: #50649c;
}

.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point {
  stroke: #7081b9;
}

.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #4ac7ec;
}

.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut,
.ct-chart .ct-series.ct-series-a .ct-bar {
  stroke: #33363c;
}

.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-line {
  stroke: #f3c74d;
}

.ct-chart .ct-series.ct-series-c .ct-bar {
  stroke: #222429;
}

.ct-chart .ct-series.ct-series-e .ct-slice-donut,
.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point {
  stroke: #1ecab8;
}

.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #f3c74d;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point {
  stroke: #ff5da0;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point {
  stroke: #7551e9;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point {
  stroke: #ed3540;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #33363c;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #4ac7ec;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #50649c;
}

.ct-grid.ct-vertical,
.ct-grid.ct-horizontal {
  stroke: #222429;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 10px;
  padding: 2px 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  background: #262f50;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donut-chart #donut-chart-container .legend {
  position: relative;
  margin-right: -50px;
  top: -50px;
}

/*== Flot==*/
#pie-chart-container .legend div:first-child,
#website-stats .legend div:first-child, #donut-chart #donut-chart-container .legend div:first-child {
  background-color: transparent !important;
}

#pie-chart-container .legend table,
#website-stats .legend table,
#donut-chart #donut-chart-container .legend table {
  color: #fff !important;
}

/*== Apex Chart ==*/
.apexcharts-gridline {
  pointer-events: none;
  stroke: #222429;
}

.apexcharts-legend-text {
  color: #8997bd !important;
  font-family: 'Proxima Nova', sans-serif, sans-serif !important;
}

.apexcharts-pie-label {
  fill: #ffffff !important;
}

.apexcharts-xaxis text,
.apexcharts-yaxis text {
  font-family: 'Proxima Nova', sans-serif, sans-serif !important;
  fill: #8997bd;
}

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: #ffffff;
}

.britechart,
.tick text {
  font-family: 'Proxima Nova', sans-serif, sans-serif;
  font-size: .75rem;
}

.extended-x-line,
.extended-y-line,
.horizontal-grid-line,
.vertical-grid-line {
  stroke: #50649c;
}

.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value,
.tick text {
  fill: #8997bd;
}

.apex-charts {
  min-height: 10px !important;
}

.dash-apex-chart .apexcharts-toolbar {
  display: none !important;
}

.jqstooltip {
  background-color: #262f50 !important;
  color: #fff !important;
  border-color: #28365f !important;
}

.jqsfield {
  color: #fff !important;
  /*set the text color here */
}

/*clock*/
.superpose {
  color: #28365f;
  height: 350px;
  width: 100%;
}

.superpose .superclock {
  position: relative;
  width: 300px;
  margin: auto;
}

.superpose .superclock .superclock1 {
  position: absolute;
  left: 10px;
  top: 10px;
}

.superpose .superclock .superclock2 {
  position: absolute;
  left: 60px;
  top: 60px;
}

.superpose .superclock .superclock3 {
  position: absolute;
  left: 110px;
  top: 110px;
}

.gauge {
  width: 100%;
  height: 100%;
}

.size-1 {
  width: 150px;
  height: 100px;
  margin: 30px auto 0 auto;
  text-align: center;
  border: 1px solid #28365f;
  padding: 5px;
}

.size-2 {
  width: 100px;
  height: 150px;
  margin: 30px auto 0 auto;
  text-align: center;
  border: 1px solid #28365f;
  padding: 5px;
}

.size-3 {
  width: 150px;
  height: 150px;
  margin: 30px auto 0 auto;
  text-align: center;
  border: 1px solid #28365f;
  padding: 5px;
}

.gmaps, .gmaps-panaroma {
  height: 300px;
  background: #262f50;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: #33363c;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #33363c;
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #33363c;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  position: absolute;
  left: auto;
  right: 0;
  display: inline-block;
  border-radius: 50%;
  background: #222429;
  padding: 5px;
  color: #eff2f9;
  cursor: po'Proxima Nova', sans-serif;
  line-height: 20px;
  text-align: center;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.email-leftbar {
  width: 220px;
  float: left;
}

.email-leftbar .mail-list a {
  display: block;
  color: #8997bd;
  font-size: 13px;
  line-height: 24px;
  padding: 5px;
}

.email-leftbar .mail-list a:hover,
.email-leftbar .mail-list a.active {
  color: #33363c;
  font-weight: 500;
}

.email-leftbar .chat-user-box p.user-title {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
}

.email-leftbar .chat-user-box p {
  font-size: 12px;
}

.email-rightbar {
  margin-left: 240px;
}

.message-list {
  display: block;
  padding-left: 0;
}

.message-list li {
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.message-list li a {
  color: #8997bd;
}

.message-list li:hover {
  background: #222429;
  -webkit-transition-duration: .05s;
  transition-duration: .05s;
}

.message-list li .col-mail {
  float: left;
  position: relative;
}

.message-list li .col-mail-1 {
  width: 320px;
}

.message-list li .col-mail-1 .star-toggle,
.message-list li .col-mail-1 .checkbox-wrapper-mail,
.message-list li .col-mail-1 .dot {
  display: block;
  float: left;
}

.message-list li .col-mail-1 .dot {
  border: 4px solid transparent;
  border-radius: 100px;
  margin: 22px 26px 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.message-list li .col-mail-1 .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
}

.message-list li .col-mail-1 .star-toggle {
  margin-top: 18px;
  font-size: 16px;
  margin-left: 5px;
}

.message-list li .col-mail-1 .title {
  position: absolute;
  top: 0;
  left: 110px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 14px 0 0;
}

.message-list li .col-mail-2 {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}

.message-list li .col-mail-2 .subject,
.message-list li .col-mail-2 .date {
  position: absolute;
  top: 0;
}

.message-list li .col-mail-2 .subject {
  left: 0;
  right: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.message-list li .col-mail-2 .date {
  right: 0;
  width: 170px;
  padding-left: 80px;
}

.message-list li.active,
.message-list li.active:hover {
  -webkit-box-shadow: inset 3px 0 0 #33363c;
  box-shadow: inset 3px 0 0 #33363c;
}

.message-list li.unread {
  background-color: #222429;
}

.message-list li.unread a {
  color: #dee8f3;
}

.message-list .checkbox-wrapper-mail {
  cursor: po'Proxima Nova', sans-serif;
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  -webkit-box-shadow: inset 0 0 0 1px #7081b9;
  box-shadow: inset 0 0 0 1px #7081b9;
  border-radius: 1px;
}

.message-list .checkbox-wrapper-mail input {
  opacity: 0;
  cursor: po'Proxima Nova', sans-serif;
}

.message-list .checkbox-wrapper-mail input:checked ~ label {
  opacity: 1;
}

.message-list .checkbox-wrapper-mail label {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0;
  cursor: po'Proxima Nova', sans-serif;
  opacity: 0;
  margin-bottom: 0;
  -webkit-transition-duration: .05s;
  transition-duration: .05s;
  top: 0;
}

.message-list .checkbox-wrapper-mail label:before {
  content: "\F12C";
  font-family: "Material Design Icons";
  top: 0;
  height: 20px;
  color: #dee8f3;
  width: 20px;
  position: absolute;
  margin-top: -16px;
  left: 4px;
  font-size: 13px;
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }
  .email-rightbar {
    margin: 0;
  }
}

.files-nav .nav .nav-link {
  padding: 10px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.files-nav .nav .nav-link i {
  font-size: 20px;
  vertical-align: middle;
}

.files-nav .nav .nav-link.active {
  background-color: rgba(77, 121, 246, 0.05);
  color: #33363c;
}

.file-box-content {
  margin-bottom: -16px;
}

.file-box-content .file-box {
  border: 1px solid #28365f;
  border-radius: 5px;
  padding: 20px;
  width: 160px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 16px;
  background-color: #1d1f23;
}

.file-box-content .file-box .download-icon-link {
  color: #7a88af;
}

.file-box-content .file-box .download-icon-link .file-download-icon {
  font-size: 13px;
  position: relative;
  top: -10px;
  left: 110px;
}

.file-box-content .file-box .download-icon-link:hover {
  color: #33363c;
}

.file-box-content .file-box i {
  font-size: 36px;
}

.file-category {
  width: 100%;
}

.file-category .file-category-block {
  text-align: center;
  margin-right: 20px;
}

.file-category .file-category-block .file-category-folder {
  border-bottom: 2px solid transparent;
  padding: 10px 20px !important;
  border-radius: 18px;
}

.file-category .file-category-block .file-category-folder.active {
  border-bottom: 2px solid;
}

.file-category .file-category-block .file-category-folder i {
  display: block;
  font-size: 38px;
}

.category-icon i {
  font-size: 200px;
  color: #222429;
}

.invoice-head {
  border-bottom: 4px double #28365f;
}

.invoice-head .contact-detail li {
  border-left: 2px solid #28365f;
}

.invoice-head .contact-detail li i {
  font-size: 24px;
  color: #505d82;
  margin-bottom: 5px;
}

@media print {
  .logo, .page-title, .breadcrumb, .footer {
    display: none;
    margin: 0;
    padding: 0;
  }
  .left {
    display: none;
  }
  .content, .page-content-wrapper, .page-wrapper {
    margin-top: 0;
    padding-top: 0;
  }
  .content-page {
    margin-left: 0;
    margin-top: 0;
  }
  .topbar, .footer, .left-sidenav, .report-btn {
    display: none;
    margin: 0;
    padding: 0;
  }
  .content-page > .content {
    margin: 0;
  }
}

.pricingTable1 .pricing-icon i {
  width: 64px;
  height: 64px;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  border: 3px solid #2d3c6a;
  border-radius: 5px;
  color: #33363c;
  background-color: rgba(77, 121, 246, 0.18);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(77, 121, 246, 0.3);
  box-shadow: 0px 0px 0px 1px rgba(77, 121, 246, 0.3);
  -webkit-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}

.pricingTable1 .amount {
  display: block;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.pricingTable1 .pricing-content-2 {
  margin: 0 0 30px 0;
  border-bottom: 1px solid #28365f;
}

.pricingTable1 .pricing-content-2 li {
  color: #fff;
  line-height: 40px;
}

.pricingTable1 .pricing-content-2 li::before {
  content: "\f00c" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  background-color: rgba(74, 199, 236, 0.1);
  color: #4ac7ec !important;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.pricingTable1 .title1 {
  font-weight: 700;
  color: #fff;
  font-size: 20px;
  text-transform: capitalize;
}

.pricingTable1 .pricingTable-signup {
  display: block;
  padding: 14px 26px;
  background: #33363c;
  border-radius: 52px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  text-align: center;
  -webkit-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}

.pricingTable1 .pricingTable-signup:hover {
  background: #3567f5;
  color: #ffffff;
}

.pricingTable1:hover .pricing-icon i {
  color: #33363c;
  background-color: rgba(77, 121, 246, 0.4);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(77, 121, 246, 0.4);
  box-shadow: 0px 0px 0px 1px rgba(77, 121, 246, 0.4);
  -webkit-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}

.pricingTable1 .a-animate-blink {
  -webkit-animation: a-animate-blink 1s step-start 0s infinite;
  animation: a-animate-blink 1s step-start 0s infinite;
  -webkit-animation-fill-mode: initial;
  animation-fill-mode: initial;
}

@-webkit-keyframes a-animate-blink {
  50% {
    opacity: 0.0;
  }
}

@keyframes a-animate-blink {
  50% {
    opacity: 0.0;
  }
}

.activity {
  position: relative;
  border-left: 3px dotted #28365f;
  margin: 30px 16px 0;
}

.activity .item-info {
  margin-left: 40px;
  margin-bottom: 30px;
}

.activity .item-info h6 {
  font-weight: 500;
  position: relative;
  top: -3px;
  color: #fff;
  font-size: 14px;
}

.activity .item-info span {
  font-size: 11px;
}

.activity img,
.activity i {
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 34px;
  border-radius: 12%;
  position: absolute;
  left: -19px;
  color: #33363c;
  background-color: #222429;
  font-size: 20px;
  margin-top: -10px;
  -webkit-box-shadow: 0px 0px 0px 0.5px #222429;
  box-shadow: 0px 0px 0px 0.5px #222429;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.activity img.icon-primary,
.activity i.icon-primary {
  color: #33363c;
  background-color: rgba(77, 121, 246, 0.15);
}

.activity img.icon-success,
.activity i.icon-success {
  color: #1ecab8;
  background-color: rgba(30, 202, 184, 0.15);
}

.activity img.icon-purple,
.activity i.icon-purple {
  color: #7551e9;
  background-color: rgba(117, 81, 233, 0.15);
}

.activity img.icon-pink,
.activity i.icon-pink {
  color: #ff5da0;
  background-color: rgba(255, 93, 160, 0.15);
}

.activity img.icon-info,
.activity i.icon-info {
  color: #505d82;
  background-color: rgba(80, 93, 130, 0.15);
}

.activity img.icon-warning,
.activity i.icon-warning {
  color: #f3c74d;
  background-color: rgba(243, 199, 77, 0.15);
}

.activity .mdi:before, .activity .mdi-set {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* ==============
  Timeline
===================*/
.main-timeline {
  overflow: hidden;
  position: relative;
}

.main-timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #262f50;
  position: absolute;
  top: 0;
  left: 50%;
}

.main-timeline .timeline {
  padding-right: 30px;
  position: relative;
}

.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.main-timeline .timeline:first-child:before,
.main-timeline .timeline:last-child:before {
  content: "";
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid #262f50;
  background: #1d1f23;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: -3px;
}

.main-timeline .timeline:last-child:before {
  top: auto;
  bottom: 0;
}

.main-timeline .timeline-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #1d1f23;
  border: 2px solid #262f50;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -4px;
}

.main-timeline .timeline-icon:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #33363c;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.main-timeline .year {
  display: inline-block;
  padding: 8px 20px;
  margin: 0;
  font-size: 14px;
  color: #ffffff;
  background: #33363c;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 35%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-timeline .year:before {
  content: "";
  border-right: 18px solid #33363c;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  position: absolute;
  top: 0;
  left: -18px;
}

.main-timeline .timeline-content {
  width: 46.5%;
  padding: 20px;
  margin: 0 20px 0 0;
  background: #262f50;
  position: relative;
}

.main-timeline .timeline-content:after {
  content: "";
  border-left: 20px solid #262f50;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  position: absolute;
  top: 50%;
  right: -20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-timeline .title {
  float: left;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 0 20px 20px 0;
}

.main-timeline .post {
  display: inline-block;
  font-size: 13px;
  color: #8997bd;
}

.main-timeline .description {
  font-size: 14px;
  color: #7a88af;
  line-height: 24px;
  margin: 0;
  clear: both;
}

.main-timeline .timeline:nth-child(2n) {
  padding: 0 0 0 30px;
}

.main-timeline .timeline:nth-child(2n) .year {
  right: auto;
  left: 35%;
}

.main-timeline .timeline:nth-child(2n) .year:before {
  border: 18px solid transparent;
  border-right: none;
  border-left: 18px solid #33363c;
  left: auto;
  right: -18px;
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
  float: right;
  margin: 0 0 0 20px;
}

.main-timeline .timeline:nth-child(2n) .timeline-content:after {
  border-left: none;
  border-right: 20px solid #262f50;
  right: auto;
  left: -20px;
}

@media only screen and (max-width: 1200px) {
  .main-timeline .year {
    right: 30%;
  }
  .main-timeline .timeline:nth-child(2n) .year {
    left: 30%;
  }
}

@media only screen and (max-width: 990px) {
  .main-timeline .year {
    right: 25%;
  }
  .main-timeline .timeline:nth-child(2n) .year {
    left: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .main-timeline:before {
    left: 10px;
  }
  .main-timeline .timeline {
    padding: 0 0 0 30px;
    margin-bottom: 20px;
  }
  .main-timeline .timeline:last-child {
    margin-bottom: 0;
  }
  .main-timeline .timeline:first-child:before,
  .main-timeline .timeline:last-child:before {
    display: none;
  }
  .main-timeline .timeline-icon {
    margin: 0;
    position: absolute;
    top: 7px;
    left: 0;
  }
  .main-timeline .year,
  .main-timeline .timeline:nth-child(2n) .year {
    display: block;
    font-weight: bold;
    margin: 0 0 32px 30px;
    z-index: 1;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    -webkit-transform: none;
    transform: none;
  }
  .main-timeline .timeline:nth-child(2n) .year:before {
    border-left: none;
    border-right: 18px solid #33363c;
    right: auto;
    left: -18px;
  }
  .main-timeline .timeline-content {
    padding: 20px;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    width: auto;
    float: none;
    margin: 0 0 0 30px;
  }
  .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(2n) .timeline-content:after {
    border: none;
    border-bottom: 20px solid #262f50;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: -20px;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 480px) {
  .main-timeline .title {
    float: none;
    margin: 0;
  }
  .main-timeline .year,
  .main-timeline .timeline:nth-child(2n) .year {
    margin-left: 20px;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    margin-left: 10px;
  }
  .main-timeline .post {
    margin: 5px 0;
  }
}

.chat-box-center {
  border-radius: 3px;
  padding: 5px;
}


.chat-box-center .gm {
  height: 500px;
}

.chat-box-left .nav-pills {
  background-color: #222429;
}

.chat-box-left .nav-pills .nav-link {
  color: #fff;
}

.chat-box-left .nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #33363c;
  -webkit-box-shadow: -1px 10px 32px -10px rgba(77, 121, 246, 0.5);
  box-shadow: -1px 10px 32px -10px rgba(77, 121, 246, 0.5);
}

.chat-box-left .chat-search {
  margin-top: 20px;
}

.chat-box-left .chat-list {
  height: 664px !important;
}

.chat-box-left .chat-list .media + .media {
  border: 1px solid #28365f;
  margin-bottom: 5px;
  border-radius: 5px;
}

.chat-box-left .chat-list .media {
  padding: 15px;
  position: relative;
}

.chat-box-left .chat-list .media:hover, .chat-box-left .chat-list .media:focus {
  background-color: rgba(40, 54, 95, 0.5);
}

.chat-box-left .chat-list .media.new-message {
  border: 1px solid #28365f;
  background-color: #222429;
  margin-bottom: 5px;
  border-radius: 5px;
}

.chat-box-left .chat-list .media .media-left {
  position: relative;
}

.chat-box-left .chat-list .media .media-left .round-10 {
  border: 2px solid #222429;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 5px;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.chat-box-left .chat-list .media .media-body {
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.chat-box-left .chat-list .media .media-body h6 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
}

.chat-box-left .chat-list .media .media-body p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
}

.chat-box-left .chat-list .media .media-body > div:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 50px;
  text-align: right;
}

.chat-box-left .chat-list .media .media-body > div:last-child span:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #1ecab8;
  color: #ffffff;
  border-radius: 50%;
  font-size: 10px;
  width: 18px;
  height: 18px;
  margin-top: 8px;
}

.chat-box-left .chat-list .media .media-body span {
  font-size: 12px;
  color: #fff;
  display: block;
}

.chat-box-right {
  width: auto;
  background-color: #1d1f23;
  display: block;
  border-radius: 3px;
  position: relative;
  min-height: 250px;
}

.chat-box-right .chat-header {
  border-bottom: 1px solid #28365f;
  padding: 20px;
}

.chat-box-right .chat-header .media .media-body {
  margin-left: 15px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.chat-box-right .chat-header .media .media-body h6 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
}

.chat-box-right .chat-header .media .media-body p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
}

.chat-box-right .chat-header .chat-features {
  position: relative;
  top: -34px;
  float: right;
}

.chat-box-right .chat-header .chat-features a {
  color: #7081b9;
  font-size: 16px;
  margin-left: 12px;
}

.chat-box-right .chat-header .chat-features a:hover {
  color: #33363c;
}

.chat-box-right .chat-body {
  padding: 20px;
  background-repeat: repeat;
  background-attachment: fixed;
  height: 650px;
}

.chat-box-right .chat-body .chat-detail {
  min-height: 610px;
}

.chat-box-right .chat-body .chat-detail .reverse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.chat-box-right .chat-body .chat-detail .media .media-img {
  z-index: 5;
}

.chat-box-right .chat-body .chat-detail .media .media-body {
  margin-left: 14px;
}

.chat-box-right .chat-body .chat-detail .media .media-body .chat-msg {
  max-width: 80%;
  margin-bottom: 5px;
  margin-left: -61px;
}

.chat-box-right .chat-body .chat-detail .media .media-body .chat-msg:first-child p {
  padding-left: 60px;
}

.chat-box-right .chat-body .chat-detail .media .media-body .chat-msg p {
  padding: 14px;
  background-color: #28365f;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  border-radius: 50px;
}

.chat-box-right .chat-body .chat-detail .media .media-body.reverse {
  margin-right: 0;
  margin-left: 14px;
}

.chat-box-right .chat-body .chat-detail .media .media-body.reverse .chat-msg {
  max-width: 80%;
  margin-bottom: 5px;
  margin-right: -48px;
}

.chat-box-right .chat-body .chat-detail .media .media-body.reverse .chat-msg:first-child p {
  padding-right: 60px;
}

.chat-box-right .chat-body .chat-detail .media .media-body.reverse .chat-msg p {
  padding: 14px;
  background-color: #262f50;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  border-radius: 50px;
}

.chat-box-right .chat-footer {
  border-top: 1px solid #28365f;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.chat-box-right .chat-footer .media .media-body {
  margin-left: 10px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.chat-box-right .chat-footer .media .media-body h6 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
}

.chat-box-right .chat-footer .media .media-body p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
}

.chat-box-right .chat-footer .chat-features {
  position: relative;
  top: 8px;
  float: right;
}

.chat-box-right .chat-footer .chat-features a {
  color: #7081b9;
  font-size: 16px;
  margin-left: 12px;
}

.chat-box-right .chat-footer .chat-features a:hover {
  color: #33363c;
}

.chat-box-right .chat-footer input.form-control {
  border: none;
}

.chat-box-right .chat-footer .chat-admin {
  position: absolute;
  top: -40px;
  border: 2px solid #fff;
  border-radius: 50%;
}

@media (min-width: 1024px) and (max-width: 1680px) {
  .chat-box-left {
    width: 310px;
    float: left;
  }
  .chat-box-right {
    width: auto;
    margin-left: 330px;
  }
}

@media (min-width: 767px) and (max-width: 1023.98px) {
  .chat-box-left {
    width: 100%;
    float: none;
  }
  .chat-box-right {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .chat-box-left {
    float: none;
    width: 100%;
  }
  .chat-box-right {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 660px) {
  .chat-box-left {
    float: none;
    width: 100%;
  }
  .chat-box-right {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 568px) {
  .chat-box-left {
    float: none;
    width: 100%;
  }
  .chat-box-right {
    margin: 0;
    width: 100%;
  }
}

.met-pro-bg {
  background-repeat: repeat;
}

.met-profile .met-profile-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.met-profile .met-profile-main .met-profile-main-pic {
  position: relative;
  max-width: 128px;
  max-height: 128px;
  margin-right: 24px;
}

.met-profile .met-profile-main .met-profile-main-pic .fro-profile_main-pic-change {
  cursor: po'Proxima Nova', sans-serif;
  background-color: #33363c;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(32, 41, 74, 0.05);
  box-shadow: 0px 0px 20px 0px rgba(32, 41, 74, 0.05);
}

.met-profile .met-profile-main .met-profile-main-pic .fro-profile_main-pic-change i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
  font-size: 16px;
}

.met-profile .met-profile-main .met-profile_user-detail .met-user-name {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 6px;
}

.met-profile .met-profile-main .met-profile_user-detail .met-user-name-post {
  color: #7a88af;
}

.met-profile .personal-detail li {
  color: #fff;
}

.met-basic-detail h3 {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 18px;
}

.met-basic-detail p {
  line-height: 22px;
}

.own-detail {
  padding: 20px;
  width: 145px;
  height: 145px;
  text-align: center;
  border-radius: 52% 48% 23% 77% / 44% 68% 32% 56%;
  -webkit-box-shadow: 0px 0px 3px 1.25px #50649c;
  box-shadow: 0px 0px 3px 1.25px #50649c;
}

.own-detail h1 {
  font-weight: 600;
  color: #ffffff;
  margin-top: 0;
}

.own-detail h5 {
  color: #f2f2f2;
}

.own-detail.own-detail-project {
  position: absolute;
  top: 110px;
  left: -60px;
}

.own-detail.own-detail-happy {
  position: absolute;
  top: 110px;
  left: 60px;
}

@media (max-width: 767px) {
  .own-detail {
    display: none;
  }
}

#settings_detail .dropify-wrapper {
  width: 16%;
  height: 164px;
  margin-bottom: 30px;
}

.profile-nav .nav .nav-link {
  padding: 10px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
}

.profile-nav .nav .nav-link.active {
  background-color: rgba(77, 121, 246, 0.05);
  color: #33363c;
}

.profile-card .profile-socials a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.profile-card .pro-title {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
}

.profile-card .socials-data h3 {
  color: #fff;
  font-family: 'Proxima Nova', sans-serif, sans-serif;
  font-size: 18px;
}

.profile-card .action-btn {
  position: absolute;
  top: 18px;
  right: 18px;
}

/* section skills */
.skill-detail {
  color: #7a88af;
  font-family: 'Proxima Nova', sans-serif, sans-serif;
  font-size: 14px;
}

.skills .skill-box {
  margin-bottom: 30px;
}

.skills .skill-box:last-of-type {
  margin-bottom: 0;
}

.skills .skill-box .skill-title {
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.skills .skill-box .progress-line {
  background: #222429;
  height: 7px;
  margin: 0;
  position: relative;
  border: 2px solid #50649c;
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 1px 0.25px #33363c;
  box-shadow: 0px 0px 1px 0.25px #33363c;
}

.skills .skill-box .progress-line > span {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: #33363c;
}

.skills .skill-box .progress-line > span > .percent-tooltip {
  position: absolute;
  right: -15px;
  top: -30px;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
}

.cd-single-step .cd-more-info {
  background-color: #1d1f23;
  border: 5px double #50649c;
  border-radius: 5px;
}

.cd-single-step .cd-more-info h2 {
  font-size: 16px;
  margin-top: 0;
  color: #fff;
  font-family: 'Proxima Nova', sans-serif, sans-serif;
}

.cd-single-step .cd-more-info span,
.cd-single-step .cd-more-info p {
  font-family: 'Proxima Nova', sans-serif, sans-serif;
  color: #7a88af;
}

.cd-tour-wrapper.active {
  list-style-type: none;
}

.cd-tour-nav a {
  color: #fff;
  font-weight: 600;
}

.cd-tour-nav a.inactive {
  color: #28365f;
}

.faq-qa li h6 {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

#accordionExample-faq .card-header {
  background-color: #262f50;
  padding: 6px 12px;
}

#accordionExample-faq .card-header h5 button::before {
  content: "\f062";
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 600;
  position: absolute;
  top: 13px;
  left: 10px;
  transition: -webkit-transform .25s;
  -webkit-transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #33363c;
  font-size: 10px;
  background-color: rgba(77, 121, 246, 0.15);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

#accordionExample-faq .card-header h5 button.collapsed::before {
  content: "\f128";
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-size: 10px;
}

.container-filter {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
}

.container-filter li {
  list-style: none;
  display: inline-block;
}

.container-filter li a {
  display: block;
  color: #33363c !important;
  border: 1px solid #33363c;
  padding: 0px 15px;
  margin: 5px 5px;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 2px;
  cursor: po'Proxima Nova', sans-serif;
  line-height: 35px;
  -webkit-transition: all 0.6s;
  border-radius: 3px;
}

.container-filter li a.active {
  background-color: #33363c;
  color: #ffffff !important;
}

.container-filter li a:hover {
  background-color: #33363c !important;
  color: #ffffff !important;
}

.item-box {
  position: relative;
  overflow: hidden;
  display: block;
}

.item-box a {
  display: inline-block;
}

.item-box:hover .item-mask {
  opacity: 0.8;
  visibility: visible;
}

.item-box:hover .item-mask .item-caption {
  bottom: 30px;
  opacity: 1;
}

.item-box:hover .item-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  width: 100%;
}

.item-container {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  width: 100%;
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.item-mask {
  /* background: none repeat scroll 0 0 rgba(238, 25, 66, 0.61); */
  position: absolute;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  top: 0;
  left: 0px;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: repeating-radial-gradient(closest-side at 25px 35px, #33363c 15%, #262f50 40%);
}

.item-mask p {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.5px;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.item-mask .item-caption {
  position: absolute;
  bottom: -60px;
  left: 0px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  opacity: 0;
}

.row.container-grid {
  margin: 0 !important;
}

.blog-card .meta-box li {
  color: #b6c2e4;
  font-size: 13px;
  font-weight: 500;
  margin-left: 0;
  margin-right: 0;
  text-transform: uppercase;
}

.blog-card .meta-box li a {
  color: #505d82;
}

.blog-card .meta-box li::after {
  content: "|";
  margin: 0 8px;
}

.blog-card .meta-box li:last-child:after {
  content: "";
}

.blog-card h4 a {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Proxima Nova', sans-serif, sans-serif;
}

.blog-card p {
  font-family: 'Proxima Nova', sans-serif, sans-serif;
}

.dash-chart {
  position: relative;
  height: 350px;
}

.browser_users table td i {
  vertical-align: middle;
}

.impressions-data h2 {
  color: #fff;
  font-weight: 600;
}

.rep-map {
  height: 280px;
}

.report-card .report-main-icon {
  font-size: 28px;
  color: #505d82;
}

.report-card h3 {
  color: #fff;
  font-weight: 600;
}

.url-list li i {
  font-size: 24px;
  vertical-align: middle;
}

.traffic-card {
  margin: 20px 0;
}

.traffic-card h3 {
  font-size: 33px;
  font-weight: 600;
  color: #fff;
  margin: 20px 0;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 16px;
  line-height: 40px;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc-day {
  background: #1d1f23;
}

.fc-widget-header {
  background-color: #28365f;
  color: #fff;
}

.fc-widget-content {
  border: 1px solid #7081b9;
}

.fc th.fc-widget-header {
  font-size: 13px;
  line-height: 10px;
  padding: 15px 0;
  text-transform: uppercase;
  font-weight: 600;
}

.fc button {
  height: auto;
  padding: 6px 16px;
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #28365f;
}

.fc-button {
  background: transparent;
  border: 1px solid #28365f;
  color: #b6c2e4;
  font-family: inherit;
  text-transform: capitalize;
}

.fc-state-active,
.fc-state-down {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #33363c;
  border-color: #33363c;
  color: #ffffff;
  text-shadow: none;
}

.fc-text-arrow {
  font-family: arial;
  font-size: 16px;
}

.fc-state-hover {
  background: #8997bd;
}

.fc-state-highlight {
  background: #50649c;
}

.fc-cell-overlay {
  background: #50649c;
}

.fc-unthemed td.fc-today {
  background: rgba(77, 121, 246, 0.08) !important;
}

.fc .fc-row .fc-content-skeleton td {
  background: transparent !important;
}

.fc-day-top .fc-mon .fc-today {
  background: rgba(77, 121, 246, 0.1) !important;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px;
  padding: 7px 5px;
  text-align: center;
  background-color: rgba(77, 121, 246, 0.18);
  color: #33363c;
}

.external-event {
  color: #ffffff;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}

.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}

tr:first-child > td > .fc-day-grid-event {
  border-left: 5px solid;
}

.fc-button {
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fc-button:hover,
.fc-state-default:hover {
  background-color: #33363c;
  color: #ffffff;
  border-color: #33363c;
}

.fc-day.fc-widget-content.fc-other-month.fc-past,
.fc-day.fc-widget-content.fc-other-month.fc-future {
  background-image: linear-gradient(-45deg, #2c3454 25%, transparent 25%, transparent 50%, #151933 50%, #28365f 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}

.btc-volume-chart {
  height: 250px !important;
}

.eco-revene-history .nav,
.crypto-report-history .nav {
  margin-bottom: 20px;
}

.eco-revene-history .nav .nav-item .nav-link,
.crypto-report-history .nav .nav-item .nav-link {
  background-color: #28365f;
  margin-right: 5px;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
}

.eco-revene-history .nav .nav-item .nav-link.active,
.crypto-report-history .nav .nav-item .nav-link.active {
  color: #33363c;
  background-color: rgba(77, 121, 246, 0.1);
}

.market-cap-table td h5 {
  color: #fff;
}

.vol-btc {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin: 0px auto 30px;
}

.ccc-widget .histoTitle {
  display: none;
}

.ccc-widget div:first-child {
  border: none !important;
}

.ccc-widget div.histoRow:nth-of-type(2) {
  font-weight: 500 !important;
  color: #fff !important;
  font-size: 13px !important;
  background-color: #28365f !important;
}

.ccc-widget div.histoRow:nth-of-type(2) .histoChange {
  font-weight: 500;
}

.ccc-widget div.histoRow {
  border-bottom-color: #272f52 !important;
  font-size: 13px !important;
  color: #869ab8 !important;
  background-color: #1d1f23;
}

.calculator-block {
  background-color: #8997bd;
  background-repeat: repeat;
}

.calculator-block .calculator-body {
  padding: 25px;
  position: relative;
}

.calculator-block .cryptonatorwidget {
  border: none !important;
}

.calculator-block .cryptonatorwidget input,
.calculator-block .cryptonatorwidget select {
  height: 40px !important;
  line-height: 40px !important;
  margin-bottom: 10px;
  border: none;
  color: #ffffff;
  border-bottom: 1px solid;
  outline: none;
  padding-left: 10px;
  background-color: transparent;
}

.calculator-block .cryptonatorwidget input option,
.calculator-block .cryptonatorwidget select option {
  background-color: #f1f5fa;
}

.calculator-block .cryptonatorwidget div:last-child {
  display: none;
}

.transaction-history li {
  border-bottom: 1px solid #28365f;
  padding: 12px 0;
}

.transaction-history li:last-of-type {
  border: none;
  padding-bottom: 0;
}

.transaction-history .transaction-icon {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  margin-right: 12px;
}

.transaction-history .transaction-icon i {
  font-size: 20px;
  width: 36px;
  height: 36px;
  display: inline-block;
  line-height: 36px;
  text-align: center;
  background-color: rgba(77, 121, 246, 0.15);
  color: #33363c;
  border-radius: 50%;
}

.transaction-history .transaction-data h3 {
  font-size: 14px;
  color: #fff;
}

.wallet-bal-usd .wallet-title {
  font-size: 16px;
  color: #fff;
}

.wallet-bal-usd h3 {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  margin-top: 20px;
}

.wallet-bal-crypto .wallet-title {
  font-size: 16px;
  color: #fff;
}

.wallet-bal-crypto .coin-bal h3 {
  font-size: 16px;
  color: #fff;
}

.btc-price h3 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.wrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
  background: #1d1f23;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #222429;
}

.jctkr-label {
  height: 60px;
  padding: 0 17px;
  line-height: 60px;
  background: rgba(43, 85, 204, 0.9);
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  cursor: default;
}

.jctkr-label:hover {
  background: #2b55cc;
  color: #f3f6f7;
}

[class*="js-conveyor-"] ul {
  display: inline-block;
  opacity: 0.5;
}

[class*="js-conveyor-"] ul li {
  padding: 0 15px;
  line-height: 35px;
  font-size: 16px;
}

[class*="js-conveyor-"] ul li .usd-rate {
  color: #b6c2e4;
}

/*ICO List countdown*/
.countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.5rem;
}

.countdown .part {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
  padding-bottom: .5rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding: 4px 10px 10px;
}

.countdown .part::before {
  /*content: ":";*/
  left: -0.25em;
  position: absolute;
  color: #33363c;
}

.countdown .part:nth-child(1)::before,
.countdown .part.no-divider::before {
  display: none;
}

.countdown .part.days::after,
.countdown .part.hours::after,
.countdown .part.minutes::after,
.countdown .part.seconds::after {
  position: absolute;
  content: attr(data-label);
  text-align: center;
  bottom: .25rem;
  left: 0;
  width: 100%;
  font-size: .3em;
  color: inherit;
}

.countdown .part + .part {
  margin-left: .625rem;
}

.countdown .days {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.countdown .hours {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.countdown .minutes {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.countdown .seconds {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.countdown.blink .part::before {
  display: none;
}

.ico-socials span i {
  width: 28px;
  text-align: center;
  padding: 4px 5px;
  border: 1px solid #1d2c32;
  color: #8dbdd0;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.ico-socials a:hover i.fa-facebook-f {
  color: #2b55cc;
  border: 1px solid;
}

.ico-socials a:hover i.fa-twitter {
  color: #505d82;
  border: 1px solid;
}

.ico-socials a:hover i.fa-medium-m {
  color: #f1646c;
  border: 1px solid;
}

.ico-socials a:hover i.fa-telegram {
  color: #1ecab8;
  border: 1px solid;
}

.ico-socials a:hover i.fa-btc {
  color: #f3c74d;
  border: 1px solid;
}

.setting-card .setting-detail h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.setting-card .sett-card-icon {
  width: 54px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 50%;
  margin-right: 18px;
}

.setting-card .sett-card-icon i {
  font-size: 24px;
}

.setting-card .sett-card-icon.set-icon-purple {
  background-color: rgba(117, 81, 233, 0.1);
  color: #7551e9;
}

.setting-card .sett-card-icon.set-icon-success {
  background-color: rgba(30, 202, 184, 0.1);
  color: #1ecab8;
}

.setting-card .sett-card-icon.set-icon-danger {
  background-color: rgba(241, 100, 108, 0.1);
  color: #f1646c;
}

.project-budget-chart {
  height: 273px;
}

.text-primary-light-alt {
  color: #50649c;
}

.icon-info i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  font-size: 26px;
  z-index: 5;
}

.dash-info-carousel .carousel-item h2 {
  font-weight: 500;
  color: #fff;
  font-size: 16px;
}

.dash-info-carousel .carousel-control-prev,
.dash-info-carousel .carousel-control-next {
  width: 30px;
  height: 30px;
  background-color: #222429;
  border-radius: 50%;
  opacity: 1;
}

.dash-info-carousel .carousel-control-prev:hover,
.dash-info-carousel .carousel-control-next:hover {
  opacity: .9;
}

.dash-info-carousel .carousel-control-prev {
  left: auto;
  right: 38px;
  top: 0;
}

.dash-info-carousel .carousel-control-prev .carousel-control-prev-icon {
  background-image: none;
}

.dash-info-carousel .carousel-control-prev:after {
  content: '\55';
  font-family: "dripicons-v2" !important;
  font-size: .875rem;
  color: #fff;
  line-height: 1.875rem;
  margin-right: 0.48rem;
}

.dash-info-carousel .carousel-control-next {
  left: auto;
  right: 0;
  top: 0;
}

.dash-info-carousel .carousel-control-next .carousel-control-next-icon {
  background-image: none;
}

.dash-info-carousel .carousel-control-next:after {
  content: '\56';
  font-family: "dripicons-v2" !important;
  font-size: .875rem;
  color: #fff;
  line-height: 1.875rem;
  margin-right: 0.48rem;
}

.c-progress-steps {
  margin: 0;
  list-style-type: none;
  font-family: 'Proxima Nova', sans-serif, sans-serif;
}

.c-progress-steps li {
  position: relative;
  font-size: 13px;
  color: #7081b9;
  padding: 16px 0 0 36px;
}

.c-progress-steps li a {
  color: inherit;
}

.c-progress-steps li.done {
  color: #505d82;
}

.c-progress-steps li.done span {
  background-color: rgba(80, 93, 130, 0.09);
}

.c-progress-steps li.done:before {
  color: #50649c;
  content: "\f058";
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #50649c;
}

.c-progress-steps li.current {
  color: #33363c;
  font-weight: 500;
}

.c-progress-steps li.current:before {
  color: #33363c;
  content: "\f192";
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #33363c;
}

.c-progress-steps li:before {
  position: absolute;
  left: 0;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 600;
  font-size: 18px;
  background-color: #1d1f23;
  color: #222429;
  content: "\f111";
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #222429;
}

@media all and (max-width: 600px) {
  .c-progress-steps li:before {
    top: calc(50% - 8px);
    font-size: 16px;
  }
}

@media all and (min-width: 600px) {
  .c-progress-steps {
    display: table;
    list-style-type: none;
    margin: 20px auto;
    padding: 0;
    table-layout: fixed;
    width: 100%;
  }
  .c-progress-steps li {
    display: table-cell;
    text-align: center;
    padding: 0;
    padding-bottom: 34px;
    white-space: nowrap;
    position: relative;
    border-left-width: 0;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: #222429;
  }
  .c-progress-steps li.done {
    border-bottom-color: #50649c;
  }
  .c-progress-steps li.current {
    color: #33363c;
    border-bottom-color: #33363c;
  }
  .c-progress-steps li.current span {
    background-color: rgba(77, 121, 246, 0.15);
  }
  .c-progress-steps li.current:before {
    color: #33363c;
    content: "\f192";
  }
  .c-progress-steps li:before {
    bottom: -15px;
    left: 50%;
    margin-left: -16px;
  }
  .c-progress-steps li span {
    background-color: rgba(80, 93, 130, 0.1);
    border-radius: 3px;
    padding: 6px;
    -webkit-box-shadow: 0px 0px 0px 0.25px #29355f;
    box-shadow: 0px 0px 0px 0.25px #29355f;
  }
}

.workload-chart {
  height: 210px;
}

/*== todo list ==*/
.todo-list .todo-box i {
  position: absolute;
  right: 20px;
  z-index: 10;
  font-size: 16px;
  margin-top: 6px;
  color: #50649c;
}

.todo-list .todo-box .todo-task {
  padding: 4px 0;
}

.todo-list .todo-box .todo-task label {
  display: inline-block;
  margin-bottom: .5rem;
}

.todo-list .todo-box .todo-task .ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 25px;
}

.todo-list .todo-box .todo-task .ckbox span {
  padding-left: 16px;
  color: #869ab8;
}

.todo-list .todo-box .todo-task .ckbox span:before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid #50649c;
  top: 2px;
  left: 0;
  border-radius: 5px;
  position: absolute;
}

.todo-list .todo-box .todo-task .ckbox span:after {
  top: 1px;
  left: 0;
  width: 20px;
  height: 20px;
  content: "\F12C";
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 14px;
  text-align: center;
  color: #b6c2e4;
  background-color: transparent;
  line-height: 20px;
  display: none;
  border-radius: 5px;
  position: absolute;
}

.todo-list .todo-box .todo-task .ckbox input[type='checkbox'] {
  opacity: 0;
  margin: 0 5px 0 0;
}

.todo-list .todo-box .todo-task .ckbox input[type='checkbox']:checked + span:after {
  display: block;
}

.todo-list .todo-box .todo-task input:checked + span {
  text-decoration: line-through;
}

.todo-list .form-control {
  color: #f1f5fa;
  background: #50649c;
  border: 1px solid transparent;
}

.todo-list .form-control:focus {
  border-color: transparent;
  background: #1c2933;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.client-card .client-name {
  color: #fff;
}

.calendar-event .event-name h3 {
  font-size: 14px;
  color: #fff;
}

.project-invoice .table tr td h5 {
  color: #fff;
}

.team-card .user-img {
  height: 80px;
}

.team-card .online-circle {
  position: absolute;
  right: 0;
  top: 118px;
  left: 44px;
}

.team-card .team-leader {
  font-size: 16px;
  color: #fff;
}

.language-skill li span {
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 2px 8px;
  background-color: #222429;
  color: #8997bd;
  font-size: 11px;
}

.project-card {
  font-family: 'Proxima Nova', sans-serif, sans-serif;
}

.project-card .project-title {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.task-box .task-priority-icon i {
  border: 5px solid #151933;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
}

.dashboard-map {
  height: 270px;
}

.card-eco .card-eco-title {
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 2px 8px;
  position: relative;
  top: -30px;
}

.card-eco .card-eco-title.eco-title-success {
  background-color: #1ecab8;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 0px 1px #1ecab8;
  box-shadow: 0px 0px 0px 1px #1ecab8;
}

.card-eco .card-eco-title.eco-title-danger {
  background-color: #f1646c;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 0px 1px #f1646c;
  box-shadow: 0px 0px 0px 1px #f1646c;
}

.card-eco .card-eco-title.eco-title-secondary {
  background-color: #4ac7ec;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 0px 1px #4ac7ec;
  box-shadow: 0px 0px 0px 1px #4ac7ec;
}

.card-eco .card-eco-title.eco-title-purple {
  background-color: #7551e9;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 0px 1px #7551e9;
  box-shadow: 0px 0px 0px 1px #7551e9;
}

.card-eco .card-eco-title.eco-title-warning {
  background-color: #f3c74d;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 0px 1px #f3c74d;
  box-shadow: 0px 0px 0px 1px #f3c74d;
}

.card-eco .card-eco-icon {
  font-size: 28px;
}

.re-customers-detail h3 {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}

.order-list table td img {
  width: 40px;
}

.e-co-product {
  background-color: #222429;
}

.e-co-product a {
  text-align: center !important;
  padding-top: 30px;
}

.e-co-product a img {
  height: 230px;
}

.e-co-product .ribbon-box {
  position: relative;
  margin-bottom: 0;
  padding-top: 30px;
}

.e-co-product .ribbon-box .ribbon {
  position: absolute;
  color: #ffffff;
}

.e-co-product .ribbon-box .ribbon-label {
  clear: none;
  padding: 0 5px;
  height: 66px;
  width: 66px;
  line-height: 66px;
  text-align: center;
  left: 0;
  top: 0;
  border-radius: 52% 48% 23% 77% / 44% 68% 32% 56%;
  font-weight: 500;
}

.e-co-product .product-info {
  background-color: #1d1f23;
}

.e-co-product .product-info .product-title {
  font-size: 15px;
  color: #fff;
}

.e-co-product .product-info .product-price {
  color: #fff;
  font-weight: 600;
  padding: 5px 0;
  margin-bottom: 0;
  font-size: 20px;
}

.e-co-product .product-info .product-price span {
  color: #8997bd;
  font-weight: 400;
  font-size: 13px;
}

.e-co-product .product-info .product-review li {
  margin-right: 0;
}

.e-co-product .product-info .product-review li i {
  font-size: 16px;
}

.e-co-product .product-info .btn-cart {
  border-color: #28365f;
  background-color: #222429;
  color: #fff;
  border-radius: 30px;
  padding: 4px 16px;
}

.e-co-product .product-info .btn-cart:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.e-co-product .product-info .btn-wishlist,
.e-co-product .product-info .btn-quickview {
  border-color: #28365f;
  background-color: #222429;
  color: #fff;
  border-radius: 50px;
  padding: 4px 10px;
}

.e-co-product .product-info .wishlist,
.e-co-product .product-info .quickview {
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.e-co-product:hover {
  -webkit-box-shadow: 0px 0px 13px 0px rgba(40, 54, 95, 0.1);
  box-shadow: 0px 0px 13px 0px rgba(40, 54, 95, 0.1);
}

.e-co-product:hover .wishlist,
.e-co-product:hover .quickview {
  opacity: 1;
}

.offer-box {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.offer-box .offer-content {
  padding: 30px;
}

.offer-box .offer-content h3 {
  font-weight: 600;
  font-size: 30px;
  color: #fff;
}

.ribbon {
  position: absolute;
  top: 0;
  padding: 6px;
  left: -5px;
  color: #ffffff;
}

.ribbon:before {
  position: absolute;
  top: 31px;
  left: 0;
  content: "";
  border: 3px solid transparent;
}

.ribbon.ribbon-pink {
  background-color: #ff5da0;
}

.ribbon.ribbon-pink:before {
  border-top-color: #ff5da0;
  border-right-color: #ff5da0;
}

.ribbon.ribbon-secondary {
  background-color: #4ac7ec;
}

.ribbon.ribbon-secondary:before {
  border-top-color: #4ac7ec;
  border-right-color: #4ac7ec;
}

hr.thick {
  border: 0;
  height: 3px;
  max-width: 150px;
  background-image: -webkit-gradient(linear, left top, right top, from(#33363c), to(#7551e9));
  background-image: linear-gradient(to right, #33363c, #7551e9);
  border-radius: 30px;
}

.single-pro-detail .custom-border {
  width: 60px;
  height: 2px;
  background-color: #f1f5fa;
}

.single-pro-detail .pro-title {
  font-weight: 500;
  color: #fff;
  font-size: 24px;
}

.single-pro-detail .product-review li {
  margin-right: 0;
}

.single-pro-detail .product-review li i {
  font-size: 16px;
}

.single-pro-detail .pro-price {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
}

.single-pro-detail .pro-price span {
  font-size: 14px;
  color: #8997bd;
}

.single-pro-detail .quantity input {
  width: 14%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: calc(24px + 12px + 5px);
}

.single-pro-detail .pro-features li {
  line-height: 26px;
  color: #7a88af;
}

.single-pro-detail .pro-features li::before {
  content: "\f00c" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 600;
  font-size: 12px;
  color: #1ecab8 !important;
  display: inline-block;
  margin-right: 8px;
}

.single-pro-detail .form-check-inline {
  margin-right: 5px;
}

.pro-order-box {
  padding: 20px;
  border: 1px solid #28365f;
  border-radius: 3px;
  background-color: #28335c;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(40, 54, 95, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(40, 54, 95, 0.05);
}

.pro-order-box i {
  font-size: 28px;
}

.review-box {
  padding: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.review-box h1 {
  font-size: 48px;
  color: #f1646c;
}

.review-box .product-review li {
  margin-right: 0;
}

.review-box .product-review li i {
  font-size: 22px;
}

.reviewer {
  padding-bottom: 12px;
  margin-top: 8px;
  border-bottom: 2px dotted #28365f;
}

.reviewer .product-review li {
  margin-right: 0;
}

.bg-newsletters {
  background-color: #33363c;
}

.newsletters-text h4 {
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
}

.newsletters-text p {
  color: #fff;
}

.newsletters-input input {
  padding: 15px 136px 15px 20px;
  width: 100%;
  font-size: 15px;
  border: none;
  outline: none !important;
  border-radius: 40px;
  background-color: #222429;
}

.newsletters-input button {
  position: absolute;
  top: 3px;
  right: 3px;
  outline: none !important;
  border-radius: 40px;
  font-size: 17px;
  padding: 10px 30px;
  -webkit-box-shadow: 0px 0px 1px 0.2px #33363c;
  box-shadow: 0px 0px 1px 0.2px #33363c;
}

.total-payment .table thead tr th,
.total-payment table thead tr th,
.shopping-cart .table thead tr th,
.shopping-cart table thead tr th {
  font-size: 15px;
}

.total-payment .table tbody td,
.total-payment table tbody td,
.shopping-cart .table tbody td,
.shopping-cart table tbody td {
  padding: 20px 10px;
  border-top: 0;
  border-bottom: 1px solid #28365f;
}

.total-payment .table tbody td .product-name,
.total-payment table tbody td .product-name,
.shopping-cart .table tbody td .product-name,
.shopping-cart table tbody td .product-name {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.total-payment .table tbody tr:last-child td,
.total-payment table tbody tr:last-child td,
.shopping-cart .table tbody tr:last-child td,
.shopping-cart table tbody tr:last-child td {
  border-bottom: 0;
}

.total-payment {
    padding: 15px;
    border: 2px solid #2f2f33;
    border-radius: 5px;
    color: #FFF;
}

.total-payment .text-warning {
  font-weight: 700;
  color: #a9a9a9 !important;
}

.total-payment .payment-title {
  color: #fff;
  font-weight: 500;
}

.cart-promo {
  padding: 90px;
  background-position: center center;
  background-size: cover;
}

.jp-card .jp-card-front,
.jp-card .jp-card-back {
  background: #262f50 !important;
}

.billing-nav .nav .nav-link {
  padding: 8px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  border: 1px solid #28365f;
  margin-right: 5px;
  width: 100px;
  height: 70px;
}

.billing-nav .nav .nav-link.active {
  background-color: #28335c;
  color: #33363c;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(40, 54, 95, 0.05);
  box-shadow: 0px 0px 13px 0px rgba(40, 54, 95, 0.05);
}

.crm-dash-map {
  height: 310px;
}

.crm-data-card .data-icon {
  text-align: center;
}

.crm-data-card .data-icon i {
  font-size: 24px;
  line-height: 48px;
  display: inline-block;
  width: 48px;
  height: 48px;
}

.crm-data-card h3 {
  font-weight: 600;
  color: #fff;
  font-size: 24px;
}

/* Custom css */
.login-btn{
  background-color: #F15822!important;
  padding: 5px 15px;
  border: 0px;
  border-radius: 4px;
  margin-right: 5px;
  text-transform: uppercase;
  color: #000;
}
.register-btn{
  background-color: #F15822;
  padding: 5px 15px;
  border: 0px;
  border-radius: 4px;
  color: #000;
  text-transform: uppercase;
}
.login-btn2{
  background-color: transparent!important;
  padding: 5px 5px;
  border: 0px;
  border-radius: 4px;
  margin-right: 5px;
  text-transform: uppercase;
  color: #FFF;
}
.register-btn2{
  background-color: transparent!important;
  padding: 5px 5px;
  border: 0px;
  border-radius: 4px;
  color: #FFF;
  text-transform: uppercase;
}
.btn-icon{
  height: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.bg-dark-blue{
  background-color: #282828;
}
.page-wrapper{
  background-color: #000;
}
#crash-tabs-tab-manual, #crash-tabs-tab-auto{
  width: 49%;
}
.game-wrapper-card{
  background-color: #282828;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1px, rgba(0, 0, 0, 0.298) 0px 0px 1px 1px;
}
.history_{
  background-color: #282828;
}
iframe{
  display: none;
}
.game-table thead tr th{
  background-color: #000000;
}
@media (min-width: 600px){
  .MuiTab-root{
    min-width: unset!important;
  }
}
.show-mobile{
  display: none;
}
@media (max-width: 768px){
  .hide-mobile{
    display: none;
  }
  .show-mobile{
    display: block!important;
  }
  .rounded-circle{
    height: 22px!important;
  }
  .text-logo{
    display: none!important;
  }
  .btn-xs{
    background-color: transparent!important;
  }
  #cup{
    display: none!important;
  }
  .navbar-custom .crypto-balance{
    margin: 8px 5px 0!important;
  }
}
@media (min-width: 768px){
  .show-desktop{
    display: block!important;
  }
}
.bg-lightbl{
  background-color: #000000;
}
.MuiAppBar-colorTransparent{
  background-color: #282828;
}
.makeStyles-root-1{
  background-color: #282828;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1px, rgba(0, 0, 0, 0.298) 0px 0px 1px 1px;
  border-radius: 4px;
  padding-bottom: 5px;
}
.makeStyles-root-8{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1px, rgba(0, 0, 0, 0.298) 0px 0px 1px 1px;
  border-radius: 4px;
  padding-bottom: 5px;
  background-color: #282828;
}

input:focus{
  background-color: #282828;
}
/* .MuiTypography-root.MuiTypography-body1 div{
  background-color: #000000;
} */
.footer-card{
  /* background-color: #282828; */
  padding: 10px;
  border-radius: 3px;
  /* height: 100%; */
}
.no-style-list{
  list-style: none;
  padding-left: 0px;
}
.ulogo{
  height: 36px;
  margin-bottom: 10px;
}
.text-lightt{
  color: #fff!important;
}
.jss1{
  background-color: #282828;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1px, rgba(0, 0, 0, 0.298) 0px 0px 1px 1px;
  border-radius: 4px;
  padding-bottom: 5px;
}
.jss8{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1px, rgba(0, 0, 0, 0.298) 0px 0px 1px 1px;
  border-radius: 4px;
  padding-bottom: 5px;
  background-color: #282828;
}
.loading-text .img-fluid{
  max-height: 200px!important;
}
@media (min-width: 1200px){
.container {
  max-width: 1300px!important;
}}

.op-8{
  opacity: .8;
}

/* =============
   Helper classes
============= */
@media (max-width: 767px) {
  .gamer{
    padding-left: 0px!important;
  }
  .col-sm-0{
    display: none!important;
  }
  .hidden-sm {
    display: none;
  }
  .footy.text-right{
    text-align: center!important;
  }
  .footy.text-left{
    text-align: center!important;
  }
  .col-md-8.no-padding-on-mobile{
    padding: 0px!important;
  }
}
.table td{
  padding: 7px 10px!important;
}
.h-100{
  height: 100%;
}
.table td, .table th{
  padding: .25rem!important;
}
.table td{
   border-bottom: 3px solid #282828;
  background-color: #424242!important;
}
.phone-header{
  margin-top: 8px;
  font-size: 15px;
  margin-left: 10px;
}
.phone-header-mobile{
  margin-top: 5px;
  margin-left: 10px;
  border: 2px solid #F15822;
  border-radius: 6px;
  padding: 3px 5px 1px 5px;
  height: fit-content;
}
.phone-header-mobile .mdi-phone{
  font-size: 16px!important;
  line-height: 1!important;
}
.input-group-text{
  background-color: #F15822!important;
  color: #000!important;
}
.btn-group-aff{
  /*height: 70%;*/
}
.btn-wallet{
  background-color: #00aced;
  border: 1px solid transparent;
}
.btn-mpesa{
  background-color: #6EB43F;
  border: 1px solid transparent;
}